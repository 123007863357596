import { IonButton, IonInput, IonInputPasswordToggle, IonItem } from '@ionic/react';
import React, { FormEvent, useRef, useState } from 'react';
import { ApiRoutes, Routes } from '../../../types/routes';
import { useDispatch } from 'react-redux';
import { setToken } from '../../../redux/userSlice';
import { useHistory } from 'react-router';
import { storageSet } from '../../../helpers/storage';
import { StorageKeys } from '../../../types/storage';
import { apiCall } from '../../../helpers/apiCalls';
import { HTTPMethods } from '../../../types/api';
import { fetchUserData } from '../../../utils/users';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../types/user';

export const Login: React.FC = () => {
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      try {
        const body = { email: emailRef.current?.value, password: passwordRef.current?.value };
        const res = await apiCall({
          url: ApiRoutes.LOGIN, method: HTTPMethods.POST, data: body
        });

        if (res.success && res.user && res.token) {
          dispatch(setToken(res.token));
          await storageSet(StorageKeys.token, res.token);
          await fetchUserData(dispatch);
          if (res.user.type === UserType.ADMIN) {
            history.push(Routes.ADMIN_USERS);
          } else {
            history.push(Routes.AGENDA);
          }
        } else { // Handle registration failure
          console.log('Registration failed:', JSON.stringify(res));
        }
      } catch (error) {
        console.log('Error registering:', error);
        // Handle registration error
      } finally {
        setLoading(false);
      }
    }
  };

  return (<>
    {t('page.login.login')}
    <form onSubmit={handleLogin}>
      <IonInput
        ref={emailRef}
        type="text"
        name="email"
        placeholder="Email"
      />
      <IonInput
        ref={passwordRef}
        name="password"
        type="password"
        placeholder="Password"
      >
        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
      </IonInput>

      <IonButton size="small" className="cn-button" type="submit" disabled={loading} expand="block">
        {t('page.login.login')}
      </IonButton>
    </form>
    <IonItem auto-hide="false" routerLink={`/${Routes.REGISTER}`}>
      {t('page.login.register')}
    </IonItem>
  </>);
};
