import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../../components/layout';
import { getUserType, UserData, UserType } from '../../../../types/user';
import { adminGetUser } from '../../../../helpers/api/admin/user';
import { formatDate } from '../../../../components/shared/date-time/helpers';
import { Booking } from '../../../../types/booking';
import './user.scss';

interface AdminUserParams {
  userId: string;
}

export const AdminUserPage: React.FC = () => {
  const { userId } = useParams<AdminUserParams>();
  const [user, setUserData] = useState<UserData>();
  const [bookings, setBookings] = useState<Booking[]>();

  const handleGetUser = async () => {
    const response = await adminGetUser(parseInt(userId));
    setUserData(response.user);
    setBookings(response.bookings);
  }

  useEffect(() => {
    handleGetUser();
  }, []);

  return (<Layout className="user-page">
    <div>
      {user && (<>
        {user.id} {user.email} {user.password}
        {getUserType(user.type as UserType)}
        Created at {formatDate(new Date(user.createdAt))}
        Updated at {formatDate(new Date(user.updatedAt))}
      </>)}
    </div>
    <div>Bookings</div>
    {bookings?.map((b: Booking) => {
      return <div key={b.id} className="booking">
        <div>Status {b.status}</div>
        {b.asap === 1 ? <div>Asap</div> : <>
          <div>{b.date}</div>
          <div>{b.startTime}</div>
        </>}
        <div>Hours: {b.hours}</div>
        <div>{b.location}</div>
        <div>$ {parseInt(b.price) / 100}</div>
        <div>{b.cancellationReason}</div>
        <div>{b.notes}</div>
        <div>Created At: {formatDate(new Date(b.createdAt))}</div>
        <div>Update At: {formatDate(new Date(b.updatedAt))}</div>
      </div>
    })}
  </Layout>);
}