export const dateToShortString = (date: Date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JS
  const day = String(d.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return (formattedDate);
}

export const mysqlDow = (date: Date) => {
  const jsDay = date.getDay() + 1;
  return jsDay === 0 ? 7 : jsDay;
};
