import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import './agenda.scss';
import { apiCall } from '../../helpers/apiCalls';
import { HTTPMethods } from '../../types/api';
import { ApiRoutes } from '../../types/routes';
import { Booking } from '../bookings/bookings';
import FormattedDateTime from '../../components/shared/date-time';
import { IonButton, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import TimeIcon from '@rsuite/icons/Time';
import { Icon } from '@rsuite/icons';
import { ClockInAction } from '../../types/clockin';
import { useTranslation } from 'react-i18next';
import ModalConfirm from '../../components/shared/modal-confirm';

export const AgendaPage: React.FC = () => {
  const [t] = useTranslation("global");
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [isClockingIn, setIsClockingIn] = useState<boolean>(false);
  const [rejectingBooking, setRejectingBooking] = useState<Booking>();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  const handleRejectBooking = async (b: Booking) => {
    console.log("reject", b);
    const response = await apiCall({
      url: ApiRoutes.REJECT_BOOKING,
      data: { booking: b },
      method: HTTPMethods.POST,
      auth: true,
    });
    console.log("response", response);
  };

  const handleFetchBookings = async () => {
    const bookingList = await apiCall({
      url: ApiRoutes.GET_BOOKINGS,
      method: HTTPMethods.POST,
      auth: true,
    });
    setBookings(bookingList.bookings);
  };
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await handleFetchBookings();
    event.detail.complete();
  };

  const isBookingInProgress = (startTimeStr: string, hours: number): boolean => {
    const startTime = new Date(startTimeStr);
    const endTime = new Date(startTime.getTime() + hours * 60 * 60 * 1000);
    const now = new Date();
    return now >= startTime && now <= endTime;
  };

  const clockInOut = async (b: Booking) => {
    setIsClockingIn(true);
    const newStatus = b.clock_in === ClockInAction.CLOCK_IN ? ClockInAction.CLOCK_OUT : ClockInAction.CLOCK_IN;
    const body = {
      bookingId: b.booking_id,
      newStatus,
    };
    await apiCall({
      url: ApiRoutes.CLOCK_IN, method: HTTPMethods.POST, data: body,
      auth: true,
    });
    handleFetchBookings();
    setIsClockingIn(false);
  }

  useEffect(() => {
    handleFetchBookings();
  }, []);

  return (<Layout title={t('page.agenda.title')} className="agenda">
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
    <h2>{t('page.agenda.bookings')}</h2>

    <ul className="timeline">
      {bookings?.map((b: Booking) => {
        const date = b.date;
        const time = b.start_time;
        const dateTimeStr = `${date} ${time}`;
        const dateTime = new Date(`${dateTimeStr}`);
        const now = new Date();
        const bookingInProgress = isBookingInProgress(dateTimeStr, b.hours);
        const isPast = dateTime < now;
        let classNames = `item ${isPast && !bookingInProgress && 'is-past'}`;
        classNames += ` ${bookingInProgress && 'in-progress'}`

        return <li className={classNames} key={b.booking_id}>
          <div>
            <div className="time">
              <FormattedDateTime dateTime={dateTime} />
              <div>({b.hours} {t('page.agenda.hours')})</div>
            </div>
            <div className="cleaner">{b.email}</div>
            <div className="location">{b.location}</div>
            <div className="price">$ {b.price / 100}</div>
            {b.notes !== "" && (
              <div className="content">{b.notes}</div>
            )}
            {bookingInProgress && !isClockingIn ? (
              <>
                <div>{t('page.agenda.inProgress')}</div>
                <Icon onClick={() => clockInOut(b)} className={`clock-in-out ${b.clock_in === ClockInAction.CLOCK_IN && "in-progress"}`} as={TimeIcon} />
              </>
            ) : (isPast && b.clock_in === ClockInAction.CLOCK_IN && (<>
              <Icon onClick={() => clockInOut(b)} className={`clock-in-out in-progress`} as={TimeIcon} />

              <div className="reject-button-container">
                <IonButton onClick={() => {
                  setRejectingBooking(b);
                  setShowModalConfirm(true);
                }} size="small" className="cn-button">Reject</IonButton>
              </div>
            </>))}
          </div>
        </li>;
      })}
    </ul>

    <ModalConfirm showModal={showModalConfirm} title={t('page.cleanerProfile.confirMessage')}
      body={<div>
        Sure?
      </div>}
      handleClose={async (response: boolean) => {
        setShowModalConfirm(false);
        if (response && rejectingBooking) {
          handleRejectBooking(rejectingBooking);
        }
      }} />
  </Layout>);
};