import React, { useEffect, useRef, useState } from 'react';
import { Calendar as RCalendar } from 'rsuite';
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import Layout from '../../components/layout';
import { useTranslation } from 'react-i18next';
import { apiCall, apiGetMyAvailability } from '../../helpers/apiCalls';
import { HTTPMethods } from '../../types/api';
import { ApiRoutes, Routes } from '../../types/routes';
import ModalConfirm from '../../components/shared/modal-confirm';
import { ColorClass, DatePickerInstance, Unavailability } from './types';
import "./availability.scss";
import AvailabilityLegends from './availability-legends';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setEditingDate, setUnavailabilities } from '../../redux/unavailabilitySlice';
import { AddAvailability } from './add-availability/add-availability';
import { dateToShortString, mysqlDow } from '../../helpers/dates';

export type RecurringDay = {
  date: string;
};

export type RecurringType = {
  fullDay: number;
  date: string;
  dow: number;
};

const getDayTypeText = (isRecurring: RecurringType | undefined) => {
  if (isRecurring) {
    return isRecurring.fullDay ? "U" : "PA";
  } else {
    return "OK";
  }
};

const getDayTypeClass = (isRecurring: RecurringType | undefined) => {
  if (isRecurring) {
    return isRecurring.fullDay ? ColorClass.BUSY : ColorClass.PARTIAL
  } else {
    return ColorClass.AVAILABLE;
  }
}

export const AvailabilityPage: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const editingDate = useSelector((state: RootState) => state.unAvailability.editingDate);
  const unavailabilities = useSelector((state: RootState) => state.unAvailability.unavailabilities);
  const datePickerRef = useRef<HTMLDivElement & DatePickerInstance>(null);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [timeHourFrom, setTimeHourFrom] = useState<string>("");
  const [timeMinFrom, setTimeMinFrom] = useState<string>("");
  const [timeHourTo, setTimeHourTo] = useState<string>("");
  const [timeMinTo, setTimeMinTo] = useState<string>("");
  const [newUnavailabilityOk, setNewUnavailabilityOk] = useState<boolean>(false);
  const [recurringDay, setRecurringDay] = useState<boolean>(false);
  const [recurringDays, setRecurringDays] = useState<RecurringType[]>([]);

  const setAvailability = () => {
    setShowModalConfirm(true);
  };

  const handleGetMyAvailability = async () => {
    const res = await apiGetMyAvailability();
    setRecurringDays(res.unavailableRecurringDays);
    dispatch(setUnavailabilities(res.unavailabilities));
  };

  const saveNewUnavailability = async () => {
    const body = {
      date: editingDate,
      fullDay: isChecked,
      hoursFrom: timeHourFrom,
      minutesFrom: timeMinFrom,
      hoursTo: timeHourTo,
      minutesTo: timeMinTo,
      recurringDay,
    };

    await apiCall({
      url: ApiRoutes.ADD_UNAVAILABILITY,
      method: HTTPMethods.POST,
      data: body,
      auth: true,
    });
    await handleGetMyAvailability();
  }

  useEffect(() => {
    handleGetMyAvailability();
  }, []);

  const renderCell = (date: Date) => {
    const dayOfWeek = mysqlDow(date);

    let dayAvailability: RecurringType | undefined = recurringDays.find((rd: RecurringType) => {
      // console.log(rd);
      return (rd.dow === dayOfWeek) ? rd : false;
    });
    if (!dayAvailability) {
      const dayStatus = unavailabilities.find((u: Unavailability) => {
        return u.date === dateToShortString(date);
      });
      if (dayStatus) {
        // console.log(dayStatus);
      }
      dayAvailability = dayStatus ? {
        fullDay: dayStatus.fullDay,
        date: dayStatus.date,
        dow: dayOfWeek,
      } : undefined;
    }
    if (dayAvailability) {
      // console.log(dayAvailability, dayOfWeek);
    }
    const colorClass = getDayTypeClass(dayAvailability);
    const displayText = getDayTypeText(dayAvailability);

    return <div className={`day-field ${colorClass}`} onClick={() => {
      const isoDate = dateToShortString(date);
      dispatch(setEditingDate(isoDate));
      setAvailability();
    }}>
      <div>
        <div className="day-number">{date.getDate()}</div>
        <div>
          {displayText}
        </div>
      </div>
    </div>;
  };

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await handleGetMyAvailability();
    event.detail.complete();
  }

  const handleChange = (date: Date | null) => {
    if (datePickerRef.current) {
      if (date) {
        const isoDate = date.toISOString();
        dispatch(setEditingDate(isoDate));
      }
      datePickerRef.current.close();
    }
  };

  const isFullDayChange = () => {
    setIsChecked(!isChecked);
  }

  const isRecurringDayChange = () => {
    setRecurringDay(!recurringDay)
  }

  useEffect(() => {
    setNewUnavailabilityOk(isChecked || (timeHourFrom !== '' && timeMinFrom !== '' && timeHourTo !== '' && timeMinTo !== ''));
  }, [editingDate, isChecked, timeHourFrom, timeMinFrom, timeHourTo, timeMinTo]);

  return (
    <Layout title={t('page.availability.title')}>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      <div>{editingDate}</div>
      <AvailabilityLegends />

      <Link onClick={() => setShowModalConfirm(false)} to={Routes.EDIT_AVAILABILITY}>Edit date availabilities</Link>

      <RCalendar isoWeek={true} bordered className="calendar-view"
        renderCell={renderCell}
      />

      <ModalConfirm hideOkButton={!newUnavailabilityOk} showModal={showModalConfirm} title={t('page.cleanerProfile.confirMessage')}
        body={<div>
          <AddAvailability
            setTimeHourTo={setTimeHourTo} timeHourTo={timeHourTo} setTimeMinTo={setTimeMinTo} timeMinTo={timeMinTo}
            setTimeMinFrom={setTimeMinFrom} timeMinFrom={timeMinFrom} timeHourFrom={timeHourFrom}
            setTimeHourFrom={setTimeHourFrom} isRecurringDayChange={isRecurringDayChange} recurringDay={recurringDay}
            isFullDayChange={isFullDayChange} isChecked={isChecked} datePickerRef={datePickerRef} handleChange={handleChange}
          />
        </div>
        }
        handleClose={async (response: boolean) => {
          setShowModalConfirm(false);
          if (response) {
            saveNewUnavailability();
          }
        }} />
    </Layout >
  );
}
