// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-user {
  border: 1px solid gray;
  padding: 10px 5px;
  border-radius: 4px;
  margin-top: 5px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}

.page-container {
  color: var(--ion-color-primary-contrast);
}
.page-container .with-padding {
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}
.page-container .main-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.page-container .main-buttons .custom-icon {
  font-size: 64px;
}
.page-container .divider {
  color: #3b3b3b;
}
.page-container .username {
  font-size: 18px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/profile.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,wCAAA;AACF;;AAEA;EACE,wCAAA;AACF;AAAE;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,6BAAA;EACA,gBAAA;AAEJ;AAAI;EACE,eAAA;AAEN;AACE;EACE,cAAA;AACJ;AACE;EACE,eAAA;EACA,mBAAA;AACJ","sourcesContent":[".profile-user {\n  border: 1px solid gray;\n  padding: 10px 5px;\n  border-radius: 4px;\n  margin-top: 5px;\n  text-align: center;\n  color: var(--ion-color-primary-contrast);\n}\n\n.page-container {\n  color: var(--ion-color-primary-contrast);\n  .with-padding {\n    padding-top: 60px;\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n  .main-buttons {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 20px;\n\n    .custom-icon {\n      font-size: 64px;\n    }\n  }\n  .divider {\n    color: #3b3b3b;\n  }\n  .username {\n    font-size: 18px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
