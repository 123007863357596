// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-page .booking {
  border: 1px solid black;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/users/user/user.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AAAJ","sourcesContent":[".user-page {\n  .booking {\n    border: 1px solid black;\n    border-radius: 4px;\n    margin: 10px 0;\n    padding: 10px 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
