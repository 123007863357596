import { IonButton } from '@ionic/react';
import React, { ReactElement } from 'react';
import { Modal } from "rsuite";

export type ModalConfirmProps = {
  showModal: boolean,
  handleClose: (response: boolean) => void;
  title?: string;
  body: ReactElement;
  hideOkButton?: boolean;
};

export const ModalConfirm: React.FC<ModalConfirmProps> = (props) => {
  const { showModal, handleClose, title, body, hideOkButton = false } = props;
  return (
    <Modal open={showModal} onClose={() => handleClose(false)}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <IonButton className="cn-button secondary" onClick={() => handleClose(false)}>
          Cancel
        </IonButton>
        {!hideOkButton && (
          <IonButton className="cn-button" onClick={() => handleClose(true)}>
            Ok
          </IonButton>
        )}
      </Modal.Footer>
    </Modal>
  );
}
