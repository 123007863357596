import React from 'react';
import { LayoutProps } from './types';
import { IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import SideMenu from '../menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserType } from '../../types/user';
import cleanerImg from '../../theme/images/cleaner.png';
import { personOutline } from 'ionicons/icons';
import { LocationTracker } from '../location-tracker/location-tracker';
import "./layout.scss";

const pageId = "main-content";

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children, title, className, padding = true } = props;
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <>
      <LocationTracker />

      <SideMenu pageId={pageId} />

      <IonPage id={pageId}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            {title && <IonTitle>{title}
              {user && (
                <> {user.type === UserType.CLEANER ?
                  <img height="20" src={cleanerImg} /> : <>
                    <IonIcon icon={personOutline} />
                  </>}
                  &nbsp;{user.email}
                </>
              )}
            </IonTitle>}
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className={`ion-padding ${className} ${!padding && "no-padding"} ion-page-content`}>
          {children}
        </IonContent>
      </IonPage >
    </>
  );
}