import React from 'react';
import { formatDate } from './helpers';
import { FormattedDateTimeProps } from './types';
import "./date-time.scss";

export const FormattedDateTime: React.FC<FormattedDateTimeProps> = (props) => {
  const { dateTime, className } = props;

  return (<div className={`formated-time ${className}`}>
    {formatDate(dateTime)}
  </div>);
};
