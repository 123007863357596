import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'rsuite';
import { hourOptions, timeOptions } from '../../../pages/availability/add-availability/types';
import { DatePickerInstance } from '../../../pages/availability/types';
import CNSelect from '../cn-select';
import { SelectDateTimeProps } from './select-datetime-types';
import './select-datetime.scss';

export const SelectDateTime: React.FC<SelectDateTimeProps> = (props) => {
  const { onChange, value } = props;
  const datePickerRef = useRef<HTMLDivElement & DatePickerInstance>(null);
  const [bookingDate, setBookingDate] = useState<string>("");
  const [timeHour, setTimeHour] = useState<string>("");
  const [timeMin, setTimeMin] = useState<string>("");

  useEffect(() => {
    if (value) {
      setBookingDate(value.bookingDate);
      setTimeHour(value.timeHour);
      setTimeMin(value.timeMin);
    }
  }, []);

  const isValid = () => bookingDate !== "" && timeHour !== "" && timeMin !== "";

  useEffect(() => {
    onChange({ bookingDate, timeHour, timeMin, valid: isValid() })
  }, [bookingDate, timeHour, timeMin]);

  const handleSetBookingDate = (e: Date | null) => {
    if (e) {
      const month = (e.getMonth() + 1).toString();
      const day = e.getDate().toString();
      const year = e.getFullYear().toString();
      setBookingDate(e ? `${year}-${month}-${day}` : "");
      datePickerRef.current?.close();
    }
  }

  return (<div className={`select-datetime-container ${isValid() ? ' valid ' : ' invalid '}`}>
    <div>
      <DatePicker isoWeek value={new Date(bookingDate)} ref={datePickerRef} onClean={() => setBookingDate("")} onSelect={handleSetBookingDate} defaultValue={new Date(bookingDate)} />
    </div>
    <div className="hrs-min">
      <CNSelect className="cn-hour" firstValue="Hours" onChange={(e: string) => setTimeHour(e)} value={timeHour} options={hourOptions} />

      <CNSelect className="cn-time" firstValue="Minutes" onChange={(e: string) => setTimeMin(e)} value={timeMin} options={timeOptions} />
    </div>
  </div>);

}