import React, { FormEvent, useRef, useState } from 'react';
import { IonInput, IonButton, IonItem, IonInputPasswordToggle, IonSelect, IonSelectOption } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { ApiRoutes, Routes } from '../../../types/routes';
import { UserType } from '../../../types/user';
import { validateEmail, validatePassword } from '../../../helpers/verifications';
import './register.scss';
import { HTTPMethods } from '../../../types/api';
import { apiCall } from '../../../helpers/apiCalls';
import { setToken } from '../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const Register: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const userTypeRef = useRef<HTMLIonSelectElement>(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validForm = true;

    const newErrors = [];
    if (userTypeRef.current?.value !== UserType.USER && userTypeRef.current?.value !== UserType.CLEANER) {
      newErrors.push("Select a user type");
      validForm = false;
    }
    if (emailRef.current && !validateEmail(emailRef.current.value as string)) {
      newErrors.push("Type a valid valid E-mail");
      validForm = false;
    }
    if (passwordRef.current && !validatePassword(passwordRef.current.value as string)) {
      newErrors.push("Chose a valid valid password (more than 5 chars)");
      validForm = false;
    }

    if (!validForm) {
      setErrors(newErrors);
    }
    if (!loading && validForm) {
      setErrors([]);
      setLoading(true);
      try {
        const body = {
          email: emailRef.current?.value,
          password: passwordRef.current?.value,
          userType: userTypeRef.current?.value,
        };
        const res = await apiCall({
          url: ApiRoutes.REGISTER, method: HTTPMethods.POST, data: body
        });

        if (res.success) {
          dispatch(setToken(res.token));
          history.push(`/${Routes.PROFILE}`);
        } else {
          console.log('Registration failed:', res);
        }
      } catch (error) {
        console.log('Error registering:', error);
        // Handle registration error
      } finally {
        setLoading(false);
      }
    }
  };

  const onSelectChange = () => {
    setErrors([]);
  }

  return (
    <>
      <form onSubmit={handleRegister}>
        <IonInput
          onIonChange={onSelectChange}
          ref={emailRef}
          type="text"
          placeholder="Email"
        />

        <IonInput
          onIonChange={onSelectChange}
          ref={passwordRef}
          type="password"
          placeholder="Password"
        >
          <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
        </IonInput>

        <IonItem>
          <IonSelect onIonChange={onSelectChange} ref={userTypeRef} label={t('page.register.userType')} labelPlacement="floating">
            <IonSelectOption value={UserType.USER}>{t('page.register.client')}</IonSelectOption>
            <IonSelectOption value={UserType.CLEANER}>{t('page.register.cleaner')}</IonSelectOption>
          </IonSelect>
        </IonItem>

        <span className="error-container">{
          errors.map((e: string, i: number) => {
            return <div className="error-message" key={i}>{e}</div>;
          })
        }</span>
        <IonButton size="small" className="cn-button" type="submit" disabled={loading} expand="block">
          {t('page.register.registerButton')}
        </IonButton>
      </form >
      <IonItem auto-hide="false" routerLink={`/${Routes.LOGIN}`}>
        {t('page.register.alreadyHaveAccountMessage')}
      </IonItem>
    </>
  );
}
