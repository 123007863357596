import { IonButton, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Unavailability } from '../availability/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Layout from '../../components/layout';
import { useTranslation } from 'react-i18next';
import ModalConfirm from '../../components/shared/modal-confirm';
import { apiCall, apiGetMyAvailability } from '../../helpers/apiCalls';
import { setEditingDate, setUnavailabilities } from '../../redux/unavailabilitySlice';
import "./edit-availability-page.scss";
import { HTTPMethods } from '../../types/api';
import { ApiRoutes } from '../../types/routes';
import { dateToShortString, mysqlDow } from '../../helpers/dates';

export const EditAvailabilityPage: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const editingDate = useSelector((state: RootState) => state.unAvailability.editingDate);
  const unavailabilities = useSelector((state: RootState) => state.unAvailability.unavailabilities);
  const [editingUnavailability, setEditingUnavailability] = useState<Unavailability>();

  const handleGetMyAvailability = async () => {
    const res = await apiGetMyAvailability();
    if (!editingDate) {
      const today = dateToShortString(new Date())
      dispatch(setEditingDate(today));
    }
    dispatch(setUnavailabilities(res.unavailabilities));
  };

  const handleDeleteUnavailability = async (u: Unavailability) => {
    const body = {
      unavailability: u,
    }
    await apiCall({
      url: ApiRoutes.DELETE_UNAVAILABILITY,
      method: HTTPMethods.POST,
      data: body,
      auth: true,
    });
    handleGetMyAvailability();
  };

  useEffect(() => {
    handleGetMyAvailability();
  }, []);

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    handleGetMyAvailability();
    event.detail.complete();
  };

  const dateNumber = (date: Date) => {
    const number = mysqlDow(date) - 1;
    return number < 8 ? number : 1;
  };

  return (<Layout title={t('page.editAvailability.title')}>
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
    {editingDate && (
      unavailabilities?.filter((u: Unavailability) => {
        const uDate = dateToShortString(new Date(u.date));
        return uDate
      }).map((u: Unavailability, index: number, array: Unavailability[]) => {
        const date = new Date(u.date);
        const uDate = dateToShortString(date);
        const prevDate = index > 0 ? dateToShortString(new Date(array[index - 1].date)) : null;
        const shouldAddBreak = prevDate && prevDate !== uDate;
        const dateElement = <div key={u.id} className="date-container">
          <div>{u.date}</div>
          <div>
            {t(`page.editAvailability.day.${(dateNumber(new Date(date)))}`)}{' '}
            {u.fullDay ? ("Full day") : (
              <div>
                {u.hoursFrom} {u.minutesFrom} hs / {u.hoursTo} {u.minutesTo} hs
              </div>)}
            {u.recurringDay === 1 && ` Repeats every week`}
          </div>
          <IonButton size="small" onClick={() => {
            setEditingUnavailability(u);
            setShowModalConfirm(true);
          }}
            className="cn-button">Delete</IonButton>

          {shouldAddBreak && (
            <div className="dates-separator">--------- {u.date} ---------</div>
          )}
        </div>;
        return dateElement;
      })
    )}

    <ModalConfirm showModal={showModalConfirm} title={t('page.cleanerProfile.confirMessage')}
      body={<div>
        Delete {editingUnavailability?.date} {editingUnavailability?.hoursFrom}?
      </div>
      }
      handleClose={async (response: boolean) => {
        setShowModalConfirm(false);
        if (response && editingUnavailability) {
          handleDeleteUnavailability(editingUnavailability);
        }
      }} />
  </Layout>);
};
