import { IonList } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserType } from '../../types/user';
import { useTranslation } from 'react-i18next';
import "./user-profile.scss";

export const UserProfile: React.FC = () => {
  const [t] = useTranslation("global");
  const user = useSelector((state: RootState) => state.user.user);

  return (<>
    <IonList className='ion-list'>
      <div>
        {t('page.userProfile.userProfile')}
      </div>
    </IonList >
  </>
  );

};
