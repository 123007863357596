// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-button {
  padding: 5px 10px;
  background-color: #3b3b3b;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  border-radius: 10px;
}
.image-button img {
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/components/image-button/image-button.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".image-button {\n  padding: 5px 10px;\n  background-color: #3b3b3b;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  justify-content: space-between;\n  border-radius: 10px;\n  img {\n    width: 70px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
