const appUrl = "https://cleaningnow-api.oferraro.com";

export const environment = {
  production: true,
  appUrl: appUrl,
  apiUrl: `${appUrl}/api`,
  stripePublicKey: '',
  googleMapsApiKey: 'AIzaSyB2V8xqSledkwXg8K7AVSZ-SNwRBTzKqvE',
  pusher: {
    app_id: "",
    key: "",
    cluster: "",
  }
};

// HOW TO USE IT IN CODE
// import { environment } from '../../environment';
// const apiUrl = environment.apiUrl;

