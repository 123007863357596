import React from 'react';
import { CNSelectProps } from './types';

export const CNSelect: React.FC<CNSelectProps> = (props) => {
  const { onChange, value, options, firstValue, className } = props;

  return (
    <select className={className} onChange={(e) => onChange(e.target.value)} value={value}>
      {firstValue && (
        <option value="">{firstValue}</option>
      )}
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
}