export enum HTTPMethods {
  POST = 'POST',
  GET = 'GET'
}

export type ApiCall<T> = {
  method: HTTPMethods,
  body?: string,
  headers?: {
    Authorization: string,
    [key: string]: string,
  }
}
