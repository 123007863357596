import React, { useEffect, useState } from 'react';
import { apiGetSericeCheckLists } from '../../helpers/api/services';
import { CheckList } from '../../types/checkList';
import { CheckboxChangeEventDetail, IonCheckbox } from '@ionic/react';
import './service-check-list.scss';
import { ServiceCheckListProps } from './service-check-list-types';
import { IonCheckboxCustomEvent } from '@ionic/core';

export const ServiceCheckList: React.FC<ServiceCheckListProps> = (props) => {
  const { onChange, serviceList } = props;
  const [checkLists, setCheckLists] = useState<CheckList[]>([]);

  const handleGetCheckLists = async () => {
    const checkLists = await apiGetSericeCheckLists();
    setCheckLists(checkLists.checkLists);
  }

  const handleChange = (event: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) => {
    const itemId = event.target.value;
    let newServiceList: number[] = [];
    if (event.target.checked) {
      if (!newServiceList.includes(itemId)) {
        newServiceList = [...serviceList, itemId];
      }
    }
    if (!event.target.checked) {
      newServiceList = serviceList.filter(id => id !== itemId);
    }
    onChange(newServiceList);
  };

  useEffect(() => {
    handleGetCheckLists();
  }, []);

  return (<>
    {checkLists.map((checkList: CheckList) => {
      return <div key={checkList.id} className='check-item'>
        <IonCheckbox checked={serviceList.includes(checkList.id)}
          onIonChange={handleChange} labelPlacement="start" justify="space-between"
          name="check-item" value={checkList.id}
        >
          <div>{checkList.serviceName} | {checkList.description}</div>
          <div>{checkList.timeEstimated} mins {checkList.price / 100} $</div>
        </IonCheckbox>
      </div >;
    })}
  </>);
}