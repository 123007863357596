import { IonImg, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import ImagesSwiper, { SwiperSlideEntry } from '../../components/shared/images-swiper';
import './cleaner-slider.scss';
import ModalConfirm from '../../components/shared/modal-confirm';
import { CleanerSliderProps } from './types';
import { CleanerSlide } from './cleaner-slide/cleaner-slide';
import ServiceCheckList from '../../components/ServiceCheckList';

export const CleanerSlider: React.FC<CleanerSliderProps> = (props) => {
  const { cleaner, bookCleaner } = props;
  const [serviceList, setServiceList] = useState<number[]>([]);
  const [mainImg, setMainImg] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState<string>("");

  const handleClose = async (value: boolean) => {
    if (value) {
      bookCleaner(cleaner.cleaner, notes, serviceList);
    }
    setShowModal(false);
  };

  return (
    <>
      {
        cleaner.pictures.length > 0 && (
          <>
            {mainImg && (
              <IonImg className="main-image" alt="Silhouette of mountains" src={mainImg}
                onClick={() => setShowModal(true)}
              />
            )}
            <ImagesSwiper onClick={(slide: SwiperSlideEntry) => {
              if (slide.img) {
                setMainImg(slide.img);
              }
            }} className="swiper-component" slides={CleanerSlide(cleaner)} />
          </>
        )
      }
      <ModalConfirm showModal={showModal} title={`Book ${cleaner.cleaner.email}`}
        body={<div>
          <div>
            <div>
              <IonTextarea value={notes} onIonChange={(e: CustomEvent) => setNotes(e.detail.value)} placeholder="Notes" />
            </div>
          </div>
          <div>
            price:&nbsp; ${cleaner.cleaner.price / 100}
          </div>

          <ServiceCheckList serviceList={serviceList} onChange={(serviceList: number[]) => {
            setServiceList(serviceList);
          }} />
        </div >}
        handleClose={handleClose} />

      <IonCardHeader>
        <IonCardTitle>
          <div onClick={() => {
            setShowModal(true);
          }}>
            book {cleaner.cleaner.email}
          </div>
        </IonCardTitle>
        <IonCardSubtitle>Distance: {parseFloat(cleaner.cleaner.real_distance).toFixed(2)} km</IonCardSubtitle>
        <div className="status-icons">
          {cleaner.cleaner.available === 0 ? <CloseOutlineIcon /> : <CheckOutlineIcon />}
        </div>
      </IonCardHeader >
      <IonCardContent>Here s a small text description for the card content. Nothing more, nothing less.</IonCardContent>
    </>
  );
}