// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-datetime-container {
  padding: 10px 10px 5px 10px;
  margin: 10px 0;
  border-radius: 4px;
}
.select-datetime-container.invalid {
  border: 1px solid red;
}
.select-datetime-container.valid {
  border: 1px solid green;
}
.select-datetime-container .hrs-min {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/select-datetime/select-datetime.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,cAAA;EACA,kBAAA;AACF;AAAE;EACE,qBAAA;AAEJ;AAAE;EACE,uBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".select-datetime-container {\n  padding: 10px 10px 5px 10px;\n  margin: 10px 0;\n  border-radius: 4px;\n  &.invalid {\n    border: 1px solid red;\n  }\n  &.valid {\n    border: 1px solid green;\n  }\n  .hrs-min {\n    margin: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
