// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-content .ion-page-content {
  color: var(--ion-color-primary-contrast);
}
#main-content .ion-page-content.no-padding {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}
#main-content .ion-page-content h1, #main-content .ion-page-content h2, #main-content .ion-page-content h3, #main-content .ion-page-content h4, #main-content .ion-page-content h5, #main-content .ion-page-content h6, #main-content .ion-page-content p, #main-content .ion-page-content label, #main-content .ion-page-content span, #main-content .ion-page-content ion-label, #main-content .ion-page-content ion-button, #main-content .ion-page-content input, #main-content .ion-page-content textarea, #main-content .ion-page-content select {
  color: var(--ion-color-primary-contrast);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.scss"],"names":[],"mappings":"AACE;EACE,wCAAA;AAAJ;AACI;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AACN;AACI;EACE,wCAAA;AACN","sourcesContent":["#main-content {\n  .ion-page-content {\n    color: var(--ion-color-primary-contrast);\n    &.no-padding {\n      --padding-bottom: 0;\n      --padding-end: 0;\n      --padding-start: 0;\n      --padding-top: 0;\n    }\n    h1, h2, h3, h4, h5, h6, p, label, span, ion-label, ion-button, input, textarea, select {\n      color: var(--ion-color-primary-contrast);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
