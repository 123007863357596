import React from 'react';
import { IonGrid, IonRow, IonCol, IonToggle } from '@ionic/react';
import { useSelector } from 'react-redux';
import { DatePicker } from 'rsuite';
import { RootState } from '../../../redux/store';
import CNSelect from '../../../components/shared/cn-select';
import { AddAvailabilityProps, hourOptions, timeOptions } from './types';
import { DATE_PICKER_DATE_FORMAT } from '../../../types/clockin';

export const AddAvailability: React.FC<AddAvailabilityProps> = (props) => {
  const {
    datePickerRef,
    handleChange,
    isChecked,
    isFullDayChange,
    recurringDay,
    isRecurringDayChange,
    setTimeHourFrom,
    timeHourFrom,
    setTimeMinFrom,
    timeMinFrom,
    setTimeHourTo,
    timeHourTo,
    setTimeMinTo,
    timeMinTo,
  } = props;

  const editingDate = useSelector((state: RootState) => state.unAvailability.editingDate);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>

          <DatePicker isoWeek format={DATE_PICKER_DATE_FORMAT}
            value={new Date(editingDate ?? "")}
            ref={datePickerRef}
            onSelect={handleChange}
          />
        </IonCol>
        <IonCol className="align-right">
          <IonToggle checked={isChecked} onIonChange={isFullDayChange}>Full day</IonToggle>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="align-right">
          <IonToggle checked={recurringDay} onIonChange={isRecurringDayChange}>Repeats every week</IonToggle>
        </IonCol>
      </IonRow>
      {!isChecked && (
        <>
          <IonRow>
            <IonCol>
              From
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CNSelect firstValue="Hours" onChange={(e: string) => setTimeHourFrom(e)} value={timeHourFrom} options={hourOptions} />
              {' '}
              <CNSelect firstValue="Minutes" onChange={(e: string) => setTimeMinFrom(e)} value={timeMinFrom} options={timeOptions} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              To
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CNSelect firstValue="Hours" onChange={(e: string) => setTimeHourTo(e)} value={timeHourTo} options={hourOptions} />
              {' '}
              <CNSelect firstValue="Minutes" onChange={(e: string) => setTimeMinTo(e)} value={timeMinTo} options={timeOptions} />
            </IonCol>
          </IonRow>
        </>
      )
      }
    </IonGrid>
  );
};
