// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container .error-message {
  padding: 5px 5px;
  color: var(--ion-color-danger);
  background-color: var(--cn-color-primary);
}
.error-container .error-message:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.error-container .error-message:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/users/register/register.scss"],"names":[],"mappings":"AACI;EASI,gBAAA;EACA,8BAAA;EACA,yCAAA;AARR;AAFQ;EACI,2BAAA;EACA,4BAAA;AAIZ;AAFQ;EACI,8BAAA;EACA,+BAAA;AAIZ","sourcesContent":[".error-container {\n    .error-message {\n        &:first-child {\n            border-top-left-radius: 4px;\n            border-top-right-radius: 4px;\n        }\n        &:last-child {\n            border-bottom-left-radius: 4px;\n            border-bottom-right-radius: 4px;\n        }\n        padding: 5px 5px;\n        color: var(--ion-color-danger);\n        background-color: var(--cn-color-primary);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
