import { HTTPMethods } from "../../types/api";
import { ApiRoutes } from "../../types/routes";
import { apiCall } from "../apiCalls";

export const apiGetSericeCheckLists = async () => {
  return await apiCall({
    url: ApiRoutes.SERVICE_CHECK_LIST,
    method: HTTPMethods.GET,
    auth: true,
  });
}
