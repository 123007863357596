import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './types';

export const initialState: UserState = {
  user: undefined,
  pictures: [],
  cleanerStatus: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserPictures: (state, action) => {
      state.pictures = action.payload;
    },
    setCleanerStatus: (state, action) => {
      state.cleanerStatus = action.payload;
    }
  },
})

export const { setUser, setToken, setUserPictures, setCleanerStatus } = userSlice.actions

export default userSlice;
