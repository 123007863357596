// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-item {
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 5px;
}

.check-item ion-checkbox {
  --size: 32px;
  --checkbox-background-checked: #6815ec;
}

.check-item ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #6815ec;
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceCheckList/service-check-list.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,uBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,sCAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;AACF","sourcesContent":[".check-item {\n  margin: 10px 0;\n  border-radius: 4px;\n  border: 1px solid black;\n  padding: 10px 5px;\n}\n\n.check-item ion-checkbox {\n  --size: 32px;\n  --checkbox-background-checked: #6815ec;\n}\n\n.check-item ion-checkbox::part(container) {\n  border-radius: 6px;\n  border: 2px solid #6815ec;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
