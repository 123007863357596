import React, { useEffect, useState } from 'react';
import { IonButton, IonItem, IonToggle } from '@ionic/react';
import './cleaners.scss';
import Layout from '../../components/layout';
import CleanerSlider from '../cleaner-slider';
import { Cleaner, CleanerSearchResult } from './types';
import spinnerImg from '../../theme/images/clean-loader.webp';
import { apiGetHomes } from '../../helpers/api/homes';
import { Home } from '../homes/types';
import HomesList from '../../components/shared/homes-list';
import { apiFindCleaners, ApiFindCleanersParams, bookCleaner, BookCleanerParams } from '../../helpers/api/cleaners';
import SelectDateTime from '../../components/shared/select-datetime';
import { SelectDateTimeValue } from '../../components/shared/select-datetime/select-datetime-types';
import { mysqlDow } from '../../helpers/dates';
import CNSelect from '../../components/shared/cn-select';
import { serviceLastsOptions } from '../availability/add-availability/types';

type SliderImg1Props = {
  img: string;
};

export const SliderImg1 = (props: SliderImg1Props) => {
  const { img } = props;

  return (
    <img alt="Silhouette of mountains" src={img} />
  );
};

export const Cleaners: React.FC = () => {
  const [cleanersWithPictures, setCleanersWithPictures] = useState([]);
  const [searchSpiner, setShowSearchSpiner] = useState<boolean>(false);
  const [homes, setHomes] = useState<Home[]>([]);
  const [home, setHome] = useState<Home | undefined>();
  const [searchNow, setSearchNow] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<SelectDateTimeValue>();
  const [serviceLasts, setServiceLasts] = useState<string>("1");

  const handleGetHomes = async () => {
    const response = await apiGetHomes();
    setHomes(response.homes);
  };

  const handleSetHome = (home: Home | undefined) => {
    setHome(home);
  }

  useEffect(() => {
    handleGetHomes();
  }, []);

  const handleSearch = async () => {
    if (home && (dateTime && dateTime?.bookingDate || searchNow) && serviceLasts) {
      setShowSearchSpiner(true);
      let params: ApiFindCleanersParams = {
        home, asap: searchNow, serviceLasts
      };
      if (dateTime) {
        params = { ...params, dateTime };
      }
      if (dateTime?.bookingDate) {
        const mysqlDay = mysqlDow(new Date(dateTime?.bookingDate));
        params = { ...params, dow: mysqlDay };
      }
      const res = await apiFindCleaners(params);
      setCleanersWithPictures(res.cleaners);
      setShowSearchSpiner(false);
    }
  }

  const handleBookCleaner = async (cleaner: Cleaner, notes: string, serviceList: number[]) => {
    if (home && dateTime && serviceLasts) {
      const body: BookCleanerParams = {
        home: home,
        searchNow,
        serviceList,
        bookingDate: dateTime.bookingDate,
        hours: serviceLasts,
        cleanerId: cleaner.id,
        bookingTime: `${dateTime.timeHour}:${dateTime.timeMin}`,
        location: '',
        notes,
        homeId: home.id
      }
      bookCleaner(body);
    }
  }

  return (
    <Layout title="Cleaners">
      <div>
        Find a cleaner for{' '}
        <HomesList handleSetHome={handleSetHome} homes={homes} />
      </div>

      <IonItem>
        <IonToggle checked={searchNow} onIonChange={() => setSearchNow(!searchNow)} color="success">
          As soon as possible
        </IonToggle>
      </IonItem>

      {!searchNow && (<>
        <div>Date and time</div>
        <SelectDateTime value={dateTime} onChange={setDateTime} />
      </>)}

      <div>
        Hours:{' '}
        <CNSelect onChange={(hours: string) => { setServiceLasts(hours) }} value={serviceLasts} options={serviceLastsOptions} />
      </div>


      <hr />
      <div>home: {home?.id}</div>
      <div>searchNow: {searchNow}</div>
      <div>dateTime: {dateTime?.bookingDate}</div>
      <div>serviceLasts: {serviceLasts}</div>
      <hr />

      {home && (dateTime?.valid || searchNow) && (
        <IonButton size="small" className="cn-button" onClick={handleSearch} disabled={searchSpiner}>Search</IonButton>
      )}
      {searchSpiner ? <div className="spinner-container">
        <img className="find-cleaner-animation" src={spinnerImg} />
      </div> :
        cleanersWithPictures?.length > 0 && cleanersWithPictures.map((e: CleanerSearchResult, key: number) => {
          return <CleanerSlider bookCleaner={handleBookCleaner} cleaner={e} key={key} />
        })
      }
    </Layout>
  );
};

