// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

ion-tab-bar {
  height: 56px;
}

.ion-page-content {
  background-color: #1f1f1f;
  --ion-background-color: #1f1f1f;
}

#tab-bar.tab-bar {
  background: #eb674d;
  --background: #eb674d;
  width: 90% !important;
  max-width: 400px !important;
  border-radius: 30px;
  margin: 0px auto 15px auto !important;
  display: flex;
  padding: 4px 8px 4px 4px;
}
#tab-bar.tab-bar ion-tab-button {
  font-size: 16px;
  color: white;
}
#tab-bar.tab-bar ion-tab-button ion-icon {
  font-size: 42px;
}
#tab-bar.tab-bar ion-tab-button ion-label {
  display: none;
}
#tab-bar.tab-bar ion-tab-button.dashboard {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background: black;
  padding: 0 5px;
  border-radius: 30px;
}
#tab-bar.tab-bar ion-tab-button.dashboard ion-icon {
  font-size: 26px;
  display: flex;
  vertical-align: middle;
  height: 100%;
  align-items: center;
  padding: 0;
  margin: 0;
}
#tab-bar.tab-bar ion-tab-button.dashboard ion-label {
  margin: 0 10px;
  display: flex;
  height: 100%;
  vertical-align: middle;
  align-items: center;
}
#tab-bar.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,+BAAA;AACF;;AAGE;EACE,mBAAA;EACA,qBAAA;EACA,qBAAA;EACA,2BAAA;EACA,mBAAA;EACA,qCAAA;EACA,aAAA;EACA,wBAAA;AAAJ;AAEI;EACE,eAAA;EACA,YAAA;AAAN;AACM;EACE,eAAA;AACR;AACM;EACE,aAAA;AACR;AAIM;EACE,sBAAA;EACA,aAAA;EAEA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAHR;AAIQ;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,SAAA;AAFV;AAIQ;EACE,cAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;AAFV;AAOE;EACE,aAAA;AALJ","sourcesContent":[".spinner-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 50px;\n}\n\nion-tab-bar {\n  height: 56px;\n}\n\n.ion-page-content {\n  background-color: #1f1f1f;\n  --ion-background-color: #1f1f1f;\n}\n\n#tab-bar {\n  &.tab-bar {\n    background: #eb674d;\n    --background: #eb674d;\n    width: 90% !important;\n    max-width: 400px !important;\n    border-radius: 30px;\n    margin: 0px auto 15px auto !important;\n    display: flex;\n    padding: 4px 8px 4px 4px;\n\n    ion-tab-button {\n      font-size: 16px;\n      color: white;\n      ion-icon {\n        font-size: 42px;\n      }\n      ion-label {\n        display: none;\n      }\n    }\n\n    ion-tab-button {\n      &.dashboard {\n        box-sizing: border-box;\n        display: flex;\n\n        flex-direction: row;\n        background: black;\n        padding: 0 5px;\n        border-radius: 30px;\n        ion-icon {\n          font-size: 26px;\n          display: flex;\n          vertical-align: middle;\n          height: 100%;\n          align-items: center;\n          padding: 0;\n          margin: 0;\n        }\n        ion-label {\n          margin: 0 10px;\n          display: flex;\n          height: 100%;\n          vertical-align: middle;\n          align-items: center;\n        }\n      }\n    }\n  }\n  &.hidden {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
