export enum CustomIconTypes {
  WIDE = 'wide',
  DEFAULT = 'default',
}
export interface CustomIconButtonProps {
  onClick: () => void;
  icon?: string;
  text: string;
  textUnder?: boolean;
  size?: "small" | "large" | "default";
  iconSize?: "small" | "large" | "default";
  type?: CustomIconTypes;
}
