import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { adminGetUsers } from '../../../helpers/api/admin/users';
import { getUserType, UserData } from '../../../types/user';
import './admin-users.scss';
import { formatDate } from '../../../components/shared/date-time/helpers';
import { useHistory } from 'react-router';
import { Routes } from '../../../types/routes';

export type AdminUsersPageResponse = {
  users: UserData[];
}

export const AdminUsersPage: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<UserData[]>([]);

  const handleGetUsers = async () => {
    const response: AdminUsersPageResponse = await adminGetUsers();
    setUsers(response.users);
  }

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (<Layout className="users-page">
    {users.map((u: UserData) => {
      return <div className="users" key={u.id} onClick={() => history.push(`${Routes.ADMIN_USER.replace(':userId', u.id.toString())}`)}>
        <div>
          {u.id} {u.email} ({getUserType(u.type)})
        </div>
        <div>
          Created at {formatDate(new Date(u.createdAt))}
        </div>
        <div>
          Updated at {formatDate(new Date(u.updatedAt))}
        </div>
      </div>;
    })}
  </Layout>);
}

