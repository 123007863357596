import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import unAvailabilitySlice from './unavailabilitySlice';

const reducer = {
  user: userSlice.reducer,
  unAvailability: unAvailabilitySlice.reducer,
  // Add other slice reducers here if you have more
};

export default configureStore({
  reducer,
});

const rootReducer = combineReducers(reducer);

export type RootState = ReturnType<typeof rootReducer>;
