import { environment } from "../environment";
import { HTTPMethods } from "../types/api";
import { ApiRoutes } from "../types/routes";
import { StorageKeys } from "../types/storage";
import { storageGet } from "./storage";

export type AppiCallProps<T> = {
  url: ApiRoutes,
  method: HTTPMethods,
  data?: T,
  formData?: FormData,
  auth?: boolean,
}

export const apiCall = async <T>(props: AppiCallProps<T>) => {
  const { url, method, data, auth = false, formData } = props;

  let params: RequestInit = {
    method,
  };

  if (data) {
    params = {...params, body: JSON.stringify(data)}
    params.headers = {
      ...params.headers,
      'Content-Type': 'application/json'
    };
  }
  if (formData) {
    params.headers = {
      ...params.headers,
    };
    params = {...params, body: formData}
  }

  if (auth) {
    const token = await storageGet(StorageKeys.token);
    params.headers = {
      ...params.headers, 
      Authorization: `Bearer ${token}`,
    }
  }

  const fullUrl = `${environment.apiUrl}/${url}`;
  try {
    const response = await fetch(fullUrl, params);
    return response.json();
  } catch(e) {
    console.log("error", e);
    return e;
  }
};

export const apiGetMyAvailability = async () => {
  return await apiCall({
    url: ApiRoutes.AVAILABILITY,
    method: HTTPMethods.GET,
    auth: true,
  });
};