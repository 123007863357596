import React from "react";
import { ImageButtonProps } from "./image-button-types";
import "./image-button.scss";

export const ImageButton: React.FC<ImageButtonProps> = (props) => {
  const { image, text } = props;

  return (
    <div className="image-button">
      {image && (
        <div className='image'>
          <img src={image} />
        </div>
      )}
      <div className="label">{text}</div>
    </div>
  );
}