import { apiCall } from "../helpers/apiCalls";
import { storageGet } from "../helpers/storage";
import { setUser, setCleanerStatus, setToken, setUserPictures } from "../redux/userSlice";
import { HTTPMethods } from "../types/api";
import { ApiRoutes } from "../types/routes";
import { UnknownAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { StorageKeys } from "../types/storage";

export const fetchUserData = async (dispatch: Dispatch<UnknownAction>) => {
  const gotToken = await storageGet(StorageKeys.token);

  if (gotToken) {
    const userMeResponse = await apiCall({
      url: ApiRoutes.ME, method: HTTPMethods.POST, data: {}, auth: true
    });
    if (userMeResponse.success) {
      dispatch(setUser(userMeResponse.user));
      if (userMeResponse.cleanerStatus) {
        dispatch(setCleanerStatus(userMeResponse.cleanerStatus));
      }
      dispatch(setToken(gotToken));
      if (userMeResponse.pictures) {
        dispatch(setUserPictures(userMeResponse.pictures));
      }
    } else {
      dispatch(setUser(null));
      dispatch(setToken(null));
    }
  }
};
