import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import './App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/styles.scss'
import { useDispatch, useSelector } from 'react-redux';
import { AppRoute, appRoutes, Routes } from './types/routes';
import { RootState } from './redux/store';
import { fetchUserData } from './utils/users';
import { useTranslation } from 'react-i18next';
import { storageGet } from './helpers/storage';
import { StorageKeys } from './types/storage';
import { UserType } from './types/user';

setupIonicReact();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [loading, setLoading] = useState(true);
  const [calledMe, setCalledMe] = useState<boolean>(false);
  const token = useSelector((state: RootState) => state.user.token);
  const user = useSelector((state: RootState) => state.user.user);

  const verifyLoginToken = async () => {
    await fetchUserData(dispatch);
    setCalledMe(true);
    setLoading(false);
  };

  const loadDefaultLang = async () => {
    const lang = await storageGet(StorageKeys.language);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }

  useEffect(() => {
    loadDefaultLang();
  }, [i18n]);

  useEffect(() => {
    verifyLoginToken();
  }, [calledMe, token]);

  if (loading || !calledMe) {
    return <div className="spinner-container">
      <IonSpinner></IonSpinner>
    </div>;
  }

  return (
    <IonApp>
      <IonTabs>
        <IonRouterOutlet>
          {token && user ? (
            appRoutes.filter((r: AppRoute) => r.needsAuth && r.userTypes.includes(user?.type)).map((r: AppRoute, key: number) => {
              return <Route key={`${r.route}-${key}`} path={`/${r.route}`} component={r.component} />;
            })
          ) : (
            appRoutes.filter((r: AppRoute) => !r.needsAuth).map((r: AppRoute, key: number) => {
              return <Route exact key={`${r.route}-${key}`} path={`/${r.route}`} component={r.component} />;
            })
          )}

          <Route exact path="/">
            {user && user.type === UserType.ADMIN ? (<Redirect to={Routes.ADMIN_USERS} />) : (
              token ? <Redirect to={`/${Routes.CLEANERS}`} /> : <Redirect to={`/${Routes.REGISTER}`} />
            )}
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom" className={`tab-bar ${!token && "hidden"}`} id="tab-bar">
          {
            appRoutes.filter((r: AppRoute) => r.needsAuth && r.showAsTab).map((r: AppRoute, key: number) => {
              return (
                <IonTabButton key={key} tab={r.route} href={`/${r.route}`} className={r.class}>
                  <IonIcon aria-hidden="true" icon={r.icon} />
                  <IonLabel>{t(r.titleTranslation)}</IonLabel>
                </IonTabButton>
              )
            })
          }
        </IonTabBar>
      </IonTabs>
    </IonApp >
  );
}

export default App;