type FormatDateOptions = {
  showTime?: boolean;
}

export const formatDate = (date: Date, options?: FormatDateOptions): string => {

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  let formatedDate = `${year}-${month}-${day}`;

  if (!options || options.showTime) {
    formatedDate += ` ${hours}:${minutes}`;
  }
  return formatedDate;
}
