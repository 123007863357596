import React, { useEffect, useState } from 'react';
import { Geolocation } from '@capacitor/geolocation';
import { CapacitorConfig } from '@capacitor/cli';
// import { GoogleMap, Marker } from '@react-google-maps/api';
import { registerPlugin } from "@capacitor/core";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

export const LocationTracker: React.FC = () => {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);


  useEffect(() => {
    const handleLocationUpdate = () => {
      console.log("x");
    }
    console.log("set listener");
    document.addEventListener('checkIn', handleLocationUpdate);
    return () => {
      document.removeEventListener('checkIn', handleLocationUpdate);
    };
  }, []);





  // useEffect(() => {
  //   const trackLocation = async () => {
  //     const options = {
  //       enableHighAccuracy: true,
  //       timeout: 8000,
  //       maximumAge: 7000,
  //     };

  //     const watchId = await Geolocation.watchPosition(options, (data) => {
  //       if (data) {
  //         setLatitude(data.coords.latitude);
  //         setLongitude(data.coords.longitude);
  //         console.log("hereee", data.coords.latitude, data.coords.longitude);
  //       } else {
  //         console.log("no data for watch");
  //       }
  //     });
  // (BackgroundGeolocation as any).addWatcher(
  //   {
  //     backgroundMessage: "Cancel to prevent battery drain.",
  //     requestPermissions: true,
  //     stale: true
  //   }, async (location: any) => {
  //     if (location === null)
  //       console.log("log_for_watcher", Date.now())
  //     else {
  //       console.log("log_for_watcher", ([location.latitude.toFixed(4), location.longitude.toFixed(4)]));
  //     }
  //   }
  // );

  //     return () => {
  //       // Clean up the watchPosition when the component unmounts
  //       Geolocation.clearWatch({ id: watchId });
  //     };
  //   };

  //   trackLocation();
  // }, []);

  // return null;
  return <div>|
    Latitude: {latitude}<br />
    Longitude: {longitude}
    {/* <GoogleMap
      mapContainerStyle={{ height: '500px', width: "100%", border: "1px solid blue" }}
      center={{ lat: latitude ?? 0, lng: longitude ?? 0 }}
      zoom={15}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false
      }}
    >
      {(latitude && longitude) && (
        <Marker
          position={{ lat: latitude, lng: longitude }}
        />
      )}
    </GoogleMap> */}
  </div>
};
