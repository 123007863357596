import React from 'react';

import './register.css';

import Layout from '../../components/layout';
import Register from '../../components/users/register';
import { useTranslation } from 'react-i18next';



const RegisterPage: React.FC = () => {
  const [t] = useTranslation("global");

  return (
    <Layout title={t('page.register.title')}>
      <Register />
    </Layout>
  );
};

export default RegisterPage;
