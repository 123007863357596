import React from 'react';
import { Home } from "../../../pages/homes/types";
import { HomesListProps } from "./homes-list-types";
import { useTranslation } from 'react-i18next';

export const HomesList: React.FC<HomesListProps> = (props) => {
  const [t] = useTranslation("global");
  const { handleSetHome, homes } = props;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (handleSetHome) {
      handleSetHome(homes.find((h: Home) => h.id === parseInt(e.target.value)));
    }
  }

  return (
    <select onChange={onChange}>
      <option value={-1}>
        {t('homes.selectHome')}
      </option>
      {homes.map((h: Home) => {
        return <option key={h.id} value={h.id}>
          {h.homeName}
        </option>
      })}
    </select>
  );
};
