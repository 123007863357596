import React from 'react';
import { SwiperSlideEntry } from '../../../components/shared/images-swiper';
import { Picture } from '../../../types/picture';
import { SliderImg1 } from '../../cleaners/cleaners';
import { formCleanerImage } from '../../cleaners/helpers';
import { CleanerSearchResult } from '../../cleaners/types';

export const CleanerSlide = (cleanerWithPictures: CleanerSearchResult) => {
  const slides: SwiperSlideEntry[] = [];
  cleanerWithPictures?.pictures?.forEach((c: Picture) => {
    const img = formCleanerImage(c);
    slides.push({
      id: c.id,
      content: <SliderImg1 img={img} />,
      img: img,
    });
  });

  return slides;
};