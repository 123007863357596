import { SelectDateTimeValue } from "../../components/shared/select-datetime/select-datetime-types";
import { Home } from "../../pages/homes/types";
import { HTTPMethods } from "../../types/api";
import { ApiRoutes } from "../../types/routes";
import { apiCall } from "../apiCalls";

export type ApiFindCleanersParams = {
  home: Home;
  dow?: number;
  asap: boolean;
  dateTime?: SelectDateTimeValue;
  serviceLasts: string;
}

export const apiFindCleaners = async (params: ApiFindCleanersParams) => await apiCall({
  url: ApiRoutes.FIND_CLEANERS,
  method: HTTPMethods.POST,
  data: { home: params.home, asap: params.asap, dateTime: params.dateTime, dow: params.dow, serviceLasts: parseInt(params.serviceLasts) },
  auth: true,
});

export type BookCleanerParams = {
  serviceList: number[];
  cleanerId: number,
  bookingDate: string,
  bookingTime: string,
  hours: string,
  location: string,
  notes: string,
  searchNow: boolean,
  homeId: number,
  home: Home,
}
export const bookCleaner = async (body: BookCleanerParams) => {
  await apiCall({
      url: ApiRoutes.BOOK_CLEANER,
      method: HTTPMethods.POST,
      data: body,
      auth: true,
    });
}