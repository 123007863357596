import { IonButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { environment } from '../../../environment';
import { Picture } from '../../../types/picture';
import { ApiRoutes } from '../../../types/routes';
import { deletePicture, handleSubmitPicture } from '../../cleanerProfile/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ModalConfirm from '../modal-confirm';
import { useTranslation } from 'react-i18next';
import { fetchUserData } from '../../../utils/users';

export const UploadPicture: React.FC = () => {
  const [t] = useTranslation("global");
  const fileRef = useRef<HTMLInputElement>(null);
  const pictures = useSelector((state: RootState) => state.user.pictures);
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [editPicture, setEditPicture] = useState<number>(0);

  const callApiMe = async () => {
    await fetchUserData(dispatch);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  return (
    <div>
      {pictures.length > 0 && (<div className="pictures-title">
        {t('page.cleanerProfile.pictures')}
      </div>)}
      <div>
        <input ref={fileRef} className="hidden" type="file" accept="image/*" onChange={handleFileChange} />
        <IonButton size="small" className="cn-button" onClick={() => fileRef.current?.click()}>
          {t('page.cleanerProfile.chooseFile')}
        </IonButton>
        {fileRef.current?.value && (
          <IonButton size="small" className="cn-button" onClick={() => {
            handleSubmitPicture(file, dispatch);
            if (fileRef.current) {
              fileRef.current.value = '';
            }
          }}>
            {t('page.cleanerProfile.uploadprofilepicture')}
          </IonButton>
        )}
      </div>
      <div className="pictures-container">
        {pictures.map((p: Picture) => {
          return <div key={p.id} className="image-container">
            <img src={`${environment.appUrl}/${ApiRoutes.UPLOADS}/${p.picture}`} />
            <IonButton className="cn-button delete-button" size="small" onClick={() => {
              setShowModalConfirm(true);
              setEditPicture(p.id);
            }}>
              {t('page.cleanerProfile.delete')}
            </IonButton>
          </div>;
        })}
      </div>

      <ModalConfirm showModal={showModalConfirm} title={t('page.cleanerProfile.confirMessage')}
        body={<div>
          <img src={
            `${environment.appUrl}/${ApiRoutes.UPLOADS}/${pictures.find((p: Picture) => p.id === editPicture)?.picture}`
          } />
        </div>}
        handleClose={async (response: boolean) => {
          setShowModalConfirm(false);
          if (response) {
            const res = await deletePicture(editPicture);
            console.log("res", res);
            callApiMe();
          }
        }} />
    </div>
  )
}