import React, { useRef, useState } from 'react';
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { ImagesSwiperProps, SwiperSlideEntry } from '.';
import 'swiper/css';

export const ImagesSwiper: React.FC<ImagesSwiperProps> = (props) => {
  const [swipperInstance, setSwipperInstance] = useState<SwiperClass>();
  const swiperRef = useRef<SwiperRef>(null);
  const { slides, className, onClick } = props;

  return (
    <Swiper
      className={`swiper-wrapper ${className}`}
      onSwiper={(swiper: SwiperClass) => {
        setSwipperInstance(swiper);
      }}
      ref={swiperRef}
      spaceBetween={10}
      slidesPerView={3}
      modules={[Pagination]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true
      }}
      pagination={{
        clickable: false,
        type: 'bullets'
      }}
      navigation={true}
    >
      {slides.map((slide: SwiperSlideEntry, key: number) => {
        return <SwiperSlide onClick={() => onClick ? onClick(slide) : undefined} key={`slide-img-${slide.id}-${key}`}>
          <div className="img" style={{ backgroundImage: `url(${slide.img})` }} />
        </SwiperSlide>;
      })}
      <div className="swiper-button-prev"
        onClick={() => {
          swipperInstance?.slidePrev();
        }}>&lt;</div>
      <div className="swiper-button-next"
        onClick={() => {
          swipperInstance?.slideNext();
        }}>&gt;</div>
    </Swiper>
  );
}