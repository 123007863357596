// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-container {
  padding: 10px 0;
}
.date-container .dates-separator {
  text-align: center;
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/edit-availability-page/edit-availability-page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".date-container {\n  padding: 10px 0;\n\n  .dates-separator {\n    text-align: center;\n    margin: 20px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
