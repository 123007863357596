import React from 'react';

export const AvailabilityLegends: React.FC = () => {
  return (
    <div className="legends">
      <div className="bussy">U: Unavailable</div>
      <div className="partial">PA: Partially Available</div>
      <div className="available">OK: Full free</div>
    </div>
  );
};
