import { Home } from "../../pages/homes/types";
import { HTTPMethods } from "../../types/api";
import { ApiRoutes } from "../../types/routes";
import { apiCall } from "../apiCalls";

export const apiGetHomes = async () => await apiCall({
  url: ApiRoutes.GET_HOME,
  method: HTTPMethods.GET,
  auth: true,
});

export const apiUpdateHome = async (home: Home) => await apiCall({
  url: ApiRoutes.UPDATE_HOME,
  method: HTTPMethods.POST,
  auth: true,
  data: {home},
});

export const apiDeleteHome = async (home: Home) => {
  await apiCall({
    url: ApiRoutes.DELETE_HOME,
    method: HTTPMethods.POST,
    data: { home },
    auth: true,
  });
}
