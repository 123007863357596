// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-image {
  margin-bottom: 5px;
}

.status-icons {
  display: flex;
  gap: 5px;
  padding: 5px 0;
}

.find-cleaner-animation {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/cleaners/cleaners.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".main-image {\n  margin-bottom: 5px;\n}\n\n.status-icons {\n  display: flex;\n  gap: 5px;\n  padding: 5px 0;\n}\n\n.find-cleaner-animation {\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
