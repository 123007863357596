// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pictures-title {
  text-align: center;
  border-top: 1px solid gray;
  margin-top: 10px;
  padding: 10px 0;
  font-size: 1.5em;
}

.pictures-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.pictures-container .image-container {
  padding: 10px 5px;
  margin-top: 5px;
}
.pictures-container .image-container img {
  height: 80px;
}
.pictures-container .image-container .delete-button {
  margin-left: 10px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/cleanerProfile/profile.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,QAAA;AACF;AACE;EACE,iBAAA;EACA,eAAA;AACJ;AAAI;EACE,YAAA;AAEN;AAAI;EACE,iBAAA;EACA,cAAA;AAEN","sourcesContent":[".pictures-title {\n  text-align: center;\n  border-top: 1px solid gray;\n  margin-top: 10px;\n  padding: 10px 0;\n  font-size: 1.5em;\n}\n\n.pictures-container{\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n\n  .image-container {\n    padding: 10px 5px;\n    margin-top: 5px;\n    img {\n      height: 80px;\n    }\n    .delete-button {\n      margin-left: 10px;\n      display: block;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
