// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/rsuite/dist/rsuite.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content * {
  font-family: "Cantarell", sans-serif;
}

.cn-button {
  --background: var(--cn-color-primary);
  color: black;
  --background-hover: var(--cn-color-primary-hover);
  --background-activated: var(--cn-color-primary-hover);
  --background-focused: var(--cn-color-primary-hover);
}
.cn-button.secondary {
  --background: white;
}

.hidden {
  display: none;
}

ion-menu-button {
  --color: var(--cn-color-primary);
}

ion-spinner {
  --color: #54dc98;
  width: 100px;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/theme/styles.scss"],"names":[],"mappings":"AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,qCAAA;EACA,YAAA;EACA,iDAAA;EACA,qDAAA;EACA,mDAAA;AAAF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,gCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;AAAF","sourcesContent":["@import '../../node_modules/rsuite/dist/rsuite.min.css';\n@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&display=swap');\n\nion-content * {\n  font-family: \"Cantarell\", sans-serif;\n}\n\n.cn-button {\n  --background: var(--cn-color-primary);\n  color: black;\n  --background-hover: var(--cn-color-primary-hover);\n  --background-activated: var(--cn-color-primary-hover);\n  --background-focused: var(--cn-color-primary-hover);\n  &.secondary {\n    --background: white;\n  }\n}\n\n.hidden {\n  display: none;\n}\n\nion-menu-button {\n  --color: var(--cn-color-primary);\n}\n\nion-spinner {\n  --color: #54dc98;\n  width: 100px;\n  height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
