import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/layout';
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import { apiCall } from '../../helpers/apiCalls';
import { HTTPMethods } from '../../types/api';
import { ApiRoutes } from '../../types/routes';
import { Clockin } from './types';
import { ClockInAction } from '../../types/clockin';
import FormattedDateTime from '../../components/shared/date-time';
import "./clockins.scss";
import { useTranslation } from 'react-i18next';

const ClockinsPage: React.FC = () => {
  const [t] = useTranslation("global");
  const [clockins, setClockins] = useState<Clockin[]>([]);
  const lastCID = useRef<number>(0);

  const handleFetchClockins = async () => {
    const res = await apiCall({
      url: ApiRoutes.FETCH_CLOCKINS,
      method: HTTPMethods.POST,
      auth: true,
    });
    lastCID.current = 0;
    setClockins(res.clockins);
  }

  useEffect(() => {
    handleFetchClockins();
  }, []);

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await handleFetchClockins();
    event.detail.complete();
  };

  return (
    <Layout title={t('page.clockins.title')}>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {t('page.clockins.last100')}
      <div className="clockins">
        {clockins?.map((c: Clockin) => {
          const ciDate = c.clockin_date;
          const ciTime = c.clockin_time;
          const ciDateTimeStr = `${ciDate} ${ciTime}`;
          const ciDateTime = new Date(`${ciDateTimeStr}`);
          const className = c.action === ClockInAction.CLOCK_IN ? "clock-in" : "clock-out";

          const toReturn = <div key={c.id} className={`clockin-container ${className}`}>
            {c.booking_id !== lastCID.current ? (
              <div>
                <div className="separator"></div>
                <div className="booking-id">Booking id: {c.booking_id}</div>
              </div>) :
              <div>-</div>}
            <div>{t('page.clockin.address')}: {c.location}</div>
            <div className="action">{
              c.action === ClockInAction.CLOCK_IN ? "Clock in" : "Clock out"
            } {t('page.clockin.at')} <FormattedDateTime dateTime={ciDateTime} /> hs
            </div>
            <div>{t('page.clockin.serviceDate')}: {c.date} {c.start_time} ({c.hours} {t('page.clockin.hours')})</div>
            <div>{t('page.clockin.price')}: $ {c.price / 100}</div>
          </div>;
          lastCID.current = c.booking_id;
          return toReturn;
        })}
      </div>
    </Layout>
  );
};

export default ClockinsPage;
