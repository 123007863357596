import { IonButton, IonIcon } from "@ionic/react";
import React from "react";
import "./custom-icon-button.scss";
import { CustomIconButtonProps, CustomIconTypes } from "./custom-icon-button-types";

export const CustomIconButton: React.FC<CustomIconButtonProps> = (props) => {
  const {
    onClick,
    icon,
    text,
    textUnder = false,
    size = "default",
    iconSize = "default",
    type = CustomIconTypes.DEFAULT
  } = props;

  return (
    <IonButton onClick={onClick} size={size} className={
      `custom-icon ${textUnder && 'text-under'} ${type}`
    }>
      <div className="text-icon-container">
        {icon && (
          <div className='icon'>
            <IonIcon size={iconSize} slot="icon-only" ios={icon} md={icon}></IonIcon>
          </div>
        )}
        <div className="label">{text}</div>
      </div>
    </IonButton>
  );
}
