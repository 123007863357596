export enum HomeType {
  HOME = 'home',
  OFFICE = 'office',
  OTHER = 'other'
}

export type Home = {
  id: number;
  userId: number;
  fullAddress: string;
  homeName: string;
  homeType: string;
  lat: string;
  lng: string;
  updatedAt: string;
  createdAt: string;
}
