// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-list {
  color: var(--ion-color-primary-contrast);
}`, "",{"version":3,"sources":["webpack://./src/components/userProfile/user-profile.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;AACF","sourcesContent":[".ion-list {\n  color: var(--ion-color-primary-contrast);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
