import React from 'react';

import Layout from '../../components/layout';
import Login from '../../components/users/login';
import { useTranslation } from 'react-i18next';

const LoginPage: React.FC = () => {
  const [t] = useTranslation("global");

  return (
    <Layout title={t('page.profile.login')}>
      <Login />
    </Layout>
  );
};

export default LoginPage;
