export type DatePickerInstance = {
  close: () => void;
};

export type TimePickerInstance = {
  target: {
    value: string;
  }
  close: () => void;
};

export type Unavailability = {
  date: string;
  fullDay: number;
  hoursFrom: string;
  hoursTo: string;
  id: number;
  minutesFrom: string;
  minutesTo: string;
  recurringDay: number;
  userId: number;
};

export enum ColorClass {
  BUSY = "busy",
  PARTIAL = "partial",
  AVAILABLE = "available",
}