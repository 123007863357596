// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-right {
  text-align: right;
}

.rs-calendar-table-cell-content {
  position: relative;
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  border: 1px solid #f9f3f3;
}

.legends {
  display: flex;
  flex-direction: column;
}
.legends .bussy {
  color: red;
  background-color: black;
}
.legends .partial {
  font-weight: 600;
  color: white;
  background-color: yellowgreen;
}
.legends .available {
  background-color: white;
  color: yellowgreen;
}

.day-field {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}
.day-field.day-number {
  display: block;
}
.day-field.busy {
  background-color: black;
  color: red;
  border: 1px solid yellowgreen;
}
.day-field.partial {
  font-weight: 600;
  color: white;
  background-color: yellowgreen;
}
.day-field.available {
  background-color: white;
  color: yellowgreen;
}`, "",{"version":3,"sources":["webpack://./src/pages/availability/availability.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,UAAA;EACA,uBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,YAAA;EACA,6BAAA;AAEJ;AACE;EACE,uBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;AAAF;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,uBAAA;EACA,UAAA;EACA,6BAAA;AADJ;AAIE;EACE,gBAAA;EACA,YAAA;EACA,6BAAA;AAFJ;AAKE;EACE,uBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".align-right {\n  text-align: right;\n}\n\n.rs-calendar-table-cell-content {\n  position: relative;\n}\n\n.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {\n  border: 1px solid #f9f3f3;\n}\n\n.legends {\n  display: flex;\n  flex-direction: column;\n  .bussy {\n    color: red;\n    background-color: black;\n  }\n  .partial {\n    font-weight: 600;\n    color: white;\n    background-color: yellowgreen;\n  }\n\n  .available {\n    background-color: white;\n    color: yellowgreen;\n  }\n}\n\n.day-field {\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  border-radius: 2px;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  position: absolute;\n  cursor: pointer;\n\n  &.day-number {\n    display: block;\n  }\n\n  &.busy {  \n    background-color: black;\n    color: red;\n    border: 1px solid yellowgreen;\n  }\n\n  &.partial {\n    font-weight: 600;\n    color: white;\n    background-color: yellowgreen;\n  }\n\n  &.available {\n    background-color: white;\n    color: yellowgreen;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
