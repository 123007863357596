import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Layout from "../../components/layout";
import { useTranslation } from 'react-i18next';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { environment } from '../../environment';
import "./homes.scss";
import { InputChangeEventDetail, IonButton, IonInput, IonRefresher, IonRefresherContent, IonSelect, IonSelectOption, RefresherEventDetail } from '@ionic/react';
import { Home, HomeType } from './types';
import { apiCall } from '../../helpers/apiCalls';
import { HTTPMethods } from '../../types/api';
import { ApiRoutes } from '../../types/routes';
import ModalConfirm from '../../components/shared/modal-confirm';
import { AutocompleteMap } from '../../components/shared/autocomplete-map/autocomplete-map';
import HomesList from '../../components/shared/homes-list';
import { apiDeleteHome, apiGetHomes, apiUpdateHome } from '../../helpers/api/homes';

type Libraries = ("places")[];
const libraries: Libraries = ["places"];

export const HomesPage: React.FC = () => {
  const [t] = useTranslation("global");
  const [showModal, setShowModal] = useState(false);
  // const mapRef = useRef<GoogleMap | null>(null);
  const [mapVisible, setMapVisible] = useState<boolean>(false);
  const [newHome, setNewHome] = useState<google.maps.places.PlaceResult>();
  const [home, setHome] = useState<Home | undefined>();
  // const [center, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>({ lat: 37.1749, lng: -92.4194 });
  const [homes, setHomes] = useState<Home[]>([]);
  const [homeToDelete, setHomeToDelete] = useState<Home>();
  const [submitError, setSubmitError] = useState<string>("");
  const editingHomeName = useRef<HTMLIonInputElement>(null);

  const [homeName, setHomeName] = useState<string>("");
  const [homeType, setHomeType] = useState<HomeType>(HomeType.HOME);

  const zoom = 15;
  // const { isLoaded } = useLoadScript({
  // googleMapsApiKey: environment.googleMapsApiKey,
  //   libraries,
  // });

  // const handleSetHome = (home: Home | undefined) => {
  //   setHome(home);
  // }

  // const handleFoundAddress = (place: google.maps.places.PlaceResult | false) => {
  //   if (place) {
  //     setNewHome(place);
  //   }
  // }

  // const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
  //   await getHomes();
  //   event.detail.complete();
  // }

  // const handleDeleteHome = async (option: boolean) => {
  //   if (option && homeToDelete) {
  //     await apiDeleteHome(homeToDelete);
  //     await getHomes();
  //   }
  //   setShowModal(false);
  // }

  const updateHome = async (home: Home) => {
    const response = await apiUpdateHome({ ...home, homeName: editingHomeName.current?.value as string });
    setHomes(response.homes);
  }

  const deleteHome = (h: Home) => {
    setHomeToDelete(h);
    setShowModal(true);
  }

  const getHomes = async () => {
    const response = await apiGetHomes();
    setHomes(response.homes);
  };

  useEffect(() => {
    getHomes();
  }, []);

  // const onLoadMap = (map: google.maps.Map) => {
  //   setMap(map);
  // };

  {/** TODO: Translate this page */ }



  const saveHome = async () => {
    if (homeName.trim() === "") {
      setSubmitError(t("form.error.addHome"));
    }
    if (!newHome) {
      setSubmitError(t("form.error.addHome.latLng"));
    }
    if (homeName && newHome?.geometry?.location) {
      setSubmitError("");
      const body = {
        home_name: homeName,
        home_type: homeType,
        full_address: newHome?.formatted_address,
        lat: newHome.geometry.location.lat(),
        lng: newHome.geometry.location.lng(),
      };

      // user_id
      const response = await apiCall({
        url: ApiRoutes.SAVE_HOME,
        method: HTTPMethods.POST,
        data: body,
        auth: true,
      });
      setHomes(response.homes);
    }
  };

  // Function to handle marker drag end event
  // const onMarkerDragEnd = async (e: google.maps.MapMouseEvent) => {
  //   if (e.latLng) {
  //     const lat = e.latLng.lat();
  //     const lng = e.latLng.lng();

  //     setCenter({ lat, lng });

  //     const geocoder = new google.maps.Geocoder();
  //     // const results = geocoder.geocode({ location: { lat, lng } });

  //     // if (results.results[0]) {
  //     //   setNewHome(results.results[0]);
  //     // }
  //   }
  // };

  // if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <Layout title={t('page.homes.title')}>
      {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      Select Home: <HomesList homes={homes} handleSetHome={handleSetHome} /> */}

      {home && (<>
        <h2>Edit</h2>

        <div key={home.id}>
          <IonInput ref={editingHomeName} value={home.homeName} placeholder={t('input.home')} />
          <div>{home.fullAddress}</div>
          <IonButton onClick={() => deleteHome(home)} size="small" className="cn-button">{
            t('actionButton.delete')
          }</IonButton>
          <IonButton onClick={() => updateHome(home)} size="small" className="cn-button">{
            t('actionButton.update')
          }</IonButton>
          <hr />
        </div>
      </>)}

      <div>
        <h2>Add</h2>
        <IonInput placeholder={t('input.home')} value={homeName}
          onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
            if (e.detail.value) {
              setHomeName(e.detail.value);
            } else {
              setHomeName("");
            }
          }} />
        {submitError && (
          <div className="submit-error">{submitError}</div>
        )}
        <IonSelect value={homeType} label="Home type" labelPlacement="floating" onIonChange={(e: CustomEvent) => {
          setHomeType(e.detail.value);
        }}>
          <IonSelectOption value={HomeType.HOME}>{HomeType.HOME}</IonSelectOption>
          <IonSelectOption value={HomeType.OFFICE}>{HomeType.OFFICE}</IonSelectOption>
          <IonSelectOption value={HomeType.OTHER}>{HomeType.OTHER}</IonSelectOption>
        </IonSelect>

        <div className="search-bar-container">
          {/* <AutocompleteMap onChange={handleFoundAddress} /> */}
        </div>

        {/* {newHome && (
          <div onClick={() => {
            setMapVisible(!mapVisible);
          }}
          >
            {mapVisible ? "hide map" : "view map"}
          </div>
        )}
        <br />
        {newHome && mapVisible && (
          <GoogleMap
            ref={mapRef}
            zoom={zoom}
            center={center}
            onLoad={onLoadMap}
            mapContainerClassName="map"
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false
            }}
          >
            {newHome && newHome.geometry && (
              <Marker
                title="Marker title"
                icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
                position={{
                  lat: newHome.geometry.location.lat(),
                  lng: newHome.geometry.location.lng(),
                }}
                onDragEnd={onMarkerDragEnd} // Handle marker drag end event
              />
            )}
          </GoogleMap>
        )} */}
      </div>
      <IonButton onClick={saveHome} size="small" className="cn-button">
        Save
      </IonButton>

      {/* <ModalConfirm showModal={showModal} title={`Book `}
        body={<div>Delete?</div>} handleClose={handleDeleteHome} /> */}
    </Layout >
  );
}