import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import { apiCall } from '../../helpers/apiCalls';
import { ApiRoutes } from '../../types/routes';
import { HTTPMethods } from '../../types/api';

export type Booking = {
  booking_id: number,
  id: number,
  user_id: number,
  cleaner_id: number,
  status: number,
  date: string;
  start_time: string;
  hours: number;
  location: string;
  clock_in: number;
  price: number;
  cancellation_reason: string;
  notes: string;
  created_at: string;
  updated_at: string;
  // Booking user data:
  email: string;
}

export const BookingsPage: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);

  const handleFetchBookings = async () => {
    const bookingList = await apiCall({
      url: ApiRoutes.GET_BOOKINGS,
      method: HTTPMethods.POST,
      auth: true,
    });
    setBookings(bookingList.bookings);
  };

  useEffect(() => {
    handleFetchBookings();
  }, []);

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await handleFetchBookings();
    event.detail.complete();
  };

  return (
    <Layout title="Bookings">
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      Bookings
      {bookings?.map((b: Booking) => {
        return <div key={b.booking_id}>
          id: {b.booking_id}
          <div>Hours {b.hours}</div>
          <div>Location {b.location}</div>
          <div>Price ${b.price / 100}</div>
          <div>Notes {b.notes}</div>
          <div>Email {b.email}</div>
          <hr />
        </div>;
      })}
    </Layout>
  );
};

