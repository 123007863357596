// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  flex: 1 1;
  position: relative;
  height: 100%;
  padding-bottom: 50px;
}
.map-container .map {
  width: 100%;
  height: 100%;
}

.submit-error {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/homes/homes.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ;;AAEA;EACE,UAAA;AACF","sourcesContent":[".map-container {\n  flex: 1;\n  position: relative;\n  height: 100%;\n  padding-bottom: 50px;\n  .map {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.submit-error {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
