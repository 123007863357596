import { IonButton, IonList, IonSelect, IonSelectOption, IonSpinner, IonToggle } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './profile.scss';
import { getCurrentPosition, setStatus } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { LatLong } from './types';
import { fetchUserData } from '../../utils/users';
import { useTranslation } from 'react-i18next';
import UploadPicture from '../shared/upload-picture';
import "./cleaner-profile.scss";

export const CleanerProfile: React.FC = () => {
  const [t] = useTranslation("global");
  const distanceRef = useRef<HTMLIonSelectElement>(null);
  const priceRef = useRef<number>(55);
  const dispatch = useDispatch();
  const [latLong, setLangLong] = useState<LatLong>();
  const cleanerStatus = useSelector((state: RootState) => state.user.cleanerStatus);
  const [available, setAvailable] = useState<boolean>(cleanerStatus?.available === 1);
  const [geoPositionAllowed, setGeoPositionAllowed] = useState<boolean>(true);
  const [selectedDistance, setSelectedDistance] = useState<number>(1);
  const [isCallingSetCleanerStatus, setIsCallingSetCleanerStatus] = useState<boolean>(false);

  useEffect(() => {
    setAvailable(cleanerStatus?.available === 1);
  }, [cleanerStatus]);

  const availabilityChange = async (e: CustomEvent) => {
    try {
      const pos = await getCurrentPosition();
      if (pos) {
        setGeoPositionAllowed(true);
      } else {
        setGeoPositionAllowed(false);
      }

      setAvailable(e.detail.checked);
      if (pos && pos.coords && e.detail.checked) {
        setLangLong({ lat: pos.coords.latitude, long: pos.coords.longitude });
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  return (<>
    <IonList>
      {isCallingSetCleanerStatus ? (
        <IonSpinner name="lines"></IonSpinner>
      ) : (
        <>
          <div>
            <IonToggle checked={available} onIonChange={availabilityChange} color="success">Im {!available && <>NOT</>} available</IonToggle>
          </div>

          {available && latLong && (
            <div>
              {t('page.cleanerProfile.profile')}
              &nbsp;<a target="_blank" href={`https://www.google.com/maps/@${latLong.lat},${latLong.long}`} rel="noreferrer">{t('page.cleanerProfile.here')}</a>
            </div>
          )}
          {!geoPositionAllowed && (
            <div color="warning">
              {t('page.cleanerProfile.geolocationNotAlloweMessage')}
            </div>
          )}
        </>)}
      <div>{t('page.cleanerProfile.price')}: $ {priceRef.current}</div>

      <IonSelect ref={distanceRef} label={t('page.cleanerProfile.distance')} labelPlacement="floating"
        value={selectedDistance} className="ion-select"
        onIonChange={(e: { detail: { value: React.SetStateAction<number>; }; }) => setSelectedDistance(e.detail.value)}
      >
        <IonSelectOption value={1}>1 km</IonSelectOption>
        <IonSelectOption value={5}>5 km</IonSelectOption>
        <IonSelectOption value={10}>10 km</IonSelectOption>
      </IonSelect>

      <div>
        <IonButton size="small" className="cn-button" slot="end" onClick={async () => {
          setIsCallingSetCleanerStatus(true);
          await setStatus(available, selectedDistance, latLong, priceRef.current * 100)
          await fetchUserData(dispatch);
          setIsCallingSetCleanerStatus(false);
        }}>{t('page.cleanerProfile.setStatus')}</IonButton>
      </div>

      <UploadPicture />

    </IonList >

  </>
  );

};
