import { HTTPMethods } from "../../../types/api";
import { ApiRoutes } from "../../../types/routes";
import { apiCall } from "../../apiCalls";

export const adminGetUsers = async () => {
  return await apiCall({
      url: ApiRoutes.ADMIN_USERS,
      method: HTTPMethods.POST,
      auth: true,
    });
}

export const adminGetUser = async (userId: number) => {
  return await apiCall({
      url: ApiRoutes.ADMIN_USER,
      method: HTTPMethods.POST,
      data: { userId },
      auth: true,
    });
}