import React, { useEffect, useState } from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonSelect, IonSelectOption, IonMenuToggle } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import './menu.scss';
import { SideMenuProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoute, appRoutes, Routes } from '../../types/routes';
import { useHistory } from 'react-router';
import { setCleanerStatus, setToken, setUser, setUserPictures } from '../../redux/userSlice';
import { storageGet, storageRemove, storageSet } from '../../helpers/storage';
import { StorageKeys } from '../../types/storage';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

export const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { pageId } = props;
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state: RootState) => state.user.token);
  const user = useSelector((state: RootState) => state.user.user);
  const [currentLang, setCurrentLang] = useState<string>();

  const getCurrentLang = async () => {
    const currentLang = await storageGet(StorageKeys.language);
    if (currentLang) {
      setCurrentLang(currentLang);
    }
  }

  useEffect(() => {
    getCurrentLang();
  }, [i18n]);

  // TODO: use appRoutes to show the routes in the menu
  return (
    <IonMenu contentId={pageId}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('menu.side.menu')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonMenuToggle>
            {token && user ? (
              appRoutes.filter((r: AppRoute) => r.needsAuth && r.userTypes.includes(user.type)).map((r: AppRoute) => {
                return (
                  <IonItem key={r.route} auto-hide="true" routerLink={`/${r.route}`}>
                    <IonIcon icon={r.icon} slot="start" />
                    <IonLabel>{t(r.titleTranslation)}</IonLabel>
                  </IonItem>
                );
              })
            ) : (
              appRoutes.filter((r: AppRoute) => !r.needsAuth).map((r: AppRoute) => {
                return (
                  <IonItem key={r.route} auto-hide="true" routerLink={`/${r.route}`}>
                    <IonIcon icon={r.icon} slot="start" />
                    <IonLabel>{t(r.titleTranslation)}</IonLabel>
                  </IonItem>
                );
              })
            )}
          </IonMenuToggle>
          {token && (
            <>
              <IonItem auto-hide="true" onClick={async () => {
                dispatch(setUser(null));
                dispatch(setToken(null));
                dispatch(setUserPictures([]));
                dispatch(setCleanerStatus(undefined));
                await storageRemove(StorageKeys.token);
                history.push(Routes.REGISTER);
              }}>
                <IonMenuToggle>
                  <IonIcon icon={settingsOutline} slot="start" />
                  <IonLabel>{t('menu.side.logout')}</IonLabel>
                </IonMenuToggle>
              </IonItem>
            </>
          )}
          <IonItem>
            <IonSelect aria-label="Language" interface="popover" placeholder={t('menu.side.choseLanguage')}
              onIonChange={async (e: CustomEvent) => {
                await storageSet(StorageKeys.language, e.detail.value);
                i18n.changeLanguage(e.detail.value);
                setCurrentLang(e.detail.value);
              }}
              value={currentLang}>
              <IonSelectOption value="en">En</IonSelectOption>
              <IonSelectOption value="es">Es</IonSelectOption>
            </IonSelect>
          </IonItem>

        </IonList>
      </IonContent>
    </IonMenu>
  );
};
