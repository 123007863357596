import { gridSharp, homeOutline, homeSharp, logoWindows, personOutline, personSharp, settingsOutline, timeOutline, walletSharp } from "ionicons/icons";
import AgendaPage from "../pages/agenda";
import AvailabilityPage from "../pages/availability";
import BookingsPage from "../pages/bookings";
import Cleaners from "../pages/cleaners";
import ClockinsPage from "../pages/clockins/clockins";
import EditAvailabilityPage from "../pages/edit-availability-page";
import HomesPage from "../pages/homes";
import ProfilePage from "../pages/profile/profile";
import { UserType } from "./user";
import RegisterPage from "../pages/register/register";
import LoginPage from "../pages/login/login";
import AdminUsersPage from "../pages/admin/users";
import AdminUserPage from "../pages/admin/users/user";

export enum Routes {
  WALLET = 'wallet',
  DASHBOARD = 'dashboard',
  AGENDA = 'agenda',
  AVAILABILITY = 'availability',
  EDIT_AVAILABILITY = 'edit-availability',
  HOMES = 'homes',
  REGISTER = 'register',
  BOOKINGS = 'bookings',
  LOGIN = 'login',
  HOME = 'home',
  PROFILE = 'profile',
  CLEANERS = 'cleaners',
  CLOCKINS = 'clockins',
  ADMIN_USERS = 'admin-users',
  ADMIN_USER = 'admin-user/:userId',
}

export enum ApiRoutes {
  REGISTER = "users/register",
  UPLOAD_PICTURE = "users/upload-picture",
  DELETE_PICTURE = "users/delete-picture",
  GET_BOOKINGS = "users/get-bookings",
  FETCH_CLOCKINS = "users/clockins",
  LOGIN = "users/login",
  ME = "users/me",
  SAVE_HOME = "users/save-home",
  UPDATE_HOME = "users/update-home",
  GET_HOME = "users/get-homes",
  DELETE_HOME = "users/delete-home",
  SET_AVAILABILITY = "users/set-available",
  DELETE_UNAVAILABILITY = "users/delete-available",
  AVAILABILITY = "users/available",
  ADD_UNAVAILABILITY = "users/add-unavailability",
  FIND_CLEANERS = "users/find-cleaners",
  UPLOADS = "api/images",
  BOOK_CLEANER = "cleaners/book",
  CLOCK_IN = "users/clock-in-out",
  REJECT_BOOKING = "users/reject-booking",
  SERVICE_CHECK_LIST = "bookings/check-list",

  ADMIN_USERS = "admin/users",
  ADMIN_USER = "admin/user",
}

export type AppRoute = {
  route: Routes;
  component: React.FC;
  needsAuth: boolean;
  userTypes: UserType[];
  showAsTab: boolean;
  titleTranslation: string;
  icon?: string;
  class?: string;
}

export const appRoutes: AppRoute[] = [
  {
    route: Routes.DASHBOARD,
    component: ProfilePage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: true,
    titleTranslation: 'menu.side.dashboard',
    icon: gridSharp,
    class: "dashboard",
  },
  {
    route: Routes.HOME,
    component: HomesPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: true,
    titleTranslation: 'menu.side.home',
    icon: homeSharp,
  },
  {
    route: Routes.WALLET,
    component: ProfilePage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: true,
    titleTranslation: 'menu.side.profile',
    icon: walletSharp,
  },
  {
    route: Routes.PROFILE,
    component: ProfilePage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: true,
    titleTranslation: 'menu.side.profile',
    icon: personSharp,
  },
  {
    route: Routes.CLEANERS,
    component: Cleaners,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.side.cleaners',
    icon: settingsOutline,
  },
  {
    route: Routes.AVAILABILITY,
    component: AvailabilityPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.side.availability',
    icon: timeOutline,
  },
  {
    route: Routes.HOMES,
    component: HomesPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.side.homes',
    icon: timeOutline,
  },
  {
    route: Routes.BOOKINGS,
    component: BookingsPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.bottom.booking',
  },
  {
    route: Routes.AGENDA,
    component: AgendaPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.side.agenda',
    icon: settingsOutline,
  },
  {
    route: Routes.CLOCKINS,
    component: ClockinsPage,
    needsAuth: true,
    userTypes: [UserType.CLEANER],
    showAsTab: false,
    titleTranslation: 'menu.side.clockins',
    icon: timeOutline,
  },
  {
    route: Routes.EDIT_AVAILABILITY,
    component: EditAvailabilityPage,
    needsAuth: false,
    userTypes: [UserType.CLEANER, UserType.USER],
    showAsTab: false,
    titleTranslation: 'menu.side.availability',
  },
  // No authenticated routes
  {
    route: Routes.REGISTER,
    component: RegisterPage,
    needsAuth: false,
    userTypes: [],
    showAsTab: true,
    titleTranslation: 'menu.bottom.register',
    icon: settingsOutline,
  },
  {
    route: Routes.LOGIN,
    component: LoginPage,
    needsAuth: false,
    userTypes: [],
    showAsTab: true,
    titleTranslation: 'menu.bottom.login',
    icon: settingsOutline,
  },
  // Admin routes
  {
    route: Routes.ADMIN_USERS,
    component: AdminUsersPage,
    needsAuth: false,
    userTypes: [UserType.ADMIN],
    showAsTab: false,
    titleTranslation: 'menu.admin.users'
  },
  {
    route: Routes.ADMIN_USER,
    component: AdminUserPage,
    needsAuth: false,
    userTypes: [UserType.ADMIN],
    showAsTab: false,
    titleTranslation: 'menu.admin.user'
  }
];