export enum UserType {
  USER = 1,
  ADMIN = 2,
  CLEANER = 3,
}

export const getUserType = (userType: UserType) => {
  if (userType === UserType.ADMIN) {
    return 'Admin';
  }
  if (userType === UserType.CLEANER) {
    return 'Cleaner';
  }
  if (userType === UserType.USER) {
    return 'User';
  }
}

export type UserData = {
  id: number;
  email: string;
  type: UserType;
  password?: string;
  created_at: string;
  updated_at: string;
  createdAt: string;
  updatedAt: string;
};

export type CleanerStatus = {
  available: number;
  distance: number;
  id: number;
  latitude: string;
  longitude: string;
  price: number;
  user_id: number;
};

