import React from 'react';

import Layout from '../../components/layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserType } from '../../types/user';
import UserProfile from '../../components/userProfile';
import CleanerProfile from '../../components/cleanerProfile';
import { IonButton, IonIcon, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import './profile.scss';
import { useTranslation } from 'react-i18next';
import activityTransparent from '../../images/icon/activity-transparent.png';
import { chatboxEllipsesOutline, chatbubbleEllipsesOutline, helpCircleOutline, logoApple, settingsSharp, star, starOutline, walletOutline } from 'ionicons/icons';
import CustomIconButton from '../../components/custom-icon-button';
import { CustomIconTypes } from '../../components/custom-icon-button/custom-icon-button-types';
import ImageButton from '../../components/image-button';

const ProfilePage: React.FC = () => {
  const [t] = useTranslation("global");
  const user = useSelector((state: RootState) => state.user.user);

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    event.detail.complete();
  };

  return (
    <Layout title={t('page.profile.profile')} padding={false}>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <div className="page-container">
        <div>
          <div className='with-padding'>
            Welcome back
            <div className="username">
              {user?.email}
            </div>
            <div>
              <CustomIconButton size="small" onClick={() => { console.log("button click"); }} icon={starOutline} text={'4.7'} />
            </div>
            <div className="main-buttons">
              <ImageButton image={activityTransparent} text={'Activity'} />

              <CustomIconButton size="large" textUnder={true} onClick={() => { console.log("button click"); }} icon={walletOutline} text={'Wallet'} />

              <CustomIconButton size="large" textUnder={true} onClick={() => { console.log("button click"); }} icon={helpCircleOutline} text={'Help'} />

            </div>
          </div>
          <hr className="divider" />
          <div className='with-padding'>
            <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "40px" }}>
              <div>
                <CustomIconButton type={CustomIconTypes.WIDE} iconSize="large" size="large" onClick={() => { console.log("button click") }} icon={settingsSharp} text="Settings" />
              </div>

              <div>
                <CustomIconButton type={CustomIconTypes.WIDE} iconSize="large" size="large" onClick={() => { console.log("button click") }} icon={chatbubbleEllipsesOutline} text="Messages" />
              </div>
            </div>

          </div>
          {user && (
            <div className="profile-user">
              {user.email} ({t(`user.type.${user.type}`)})
            </div>
          )}
          {user?.type === UserType.CLEANER && <CleanerProfile />}
          {user?.type === UserType.USER && <UserProfile />}
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
