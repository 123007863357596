import { Dispatch, SetStateAction } from "react";
import { DatePickerInstance } from "../types";

export type AddAvailabilityProps = {
  datePickerRef: React.RefObject<HTMLDivElement & DatePickerInstance>;
  handleChange: (date: Date | null) => void;
  isChecked: boolean;
  isFullDayChange: () => void;
  recurringDay: boolean;
  isRecurringDayChange: () => void;
  setTimeHourFrom: Dispatch<SetStateAction<string>>;
  timeHourFrom: string;
  setTimeMinFrom: Dispatch<SetStateAction<string>>;
  timeMinFrom: string;
  setTimeHourTo: Dispatch<SetStateAction<string>>;
  timeHourTo: string;
  setTimeMinTo: Dispatch<SetStateAction<string>>;
  timeMinTo: string;
};

export const hourOptions = Array.from({ length: 25 }, (_, index) => ({
  value: index.toString().padStart(2, '0'),
  text: index.toString().padStart(2, '0'),
}));

export const timeOptions = Array.from({ length: 60 }, (_, index) => ({
  value: index.toString().padStart(2, '0'),
  text: index.toString().padStart(2, '0'),
}));

export const serviceLastsOptions = Array.from({ length: 12 }, (_, index) => ({
  value: index.toString(),
  text: index.toString(),
}));