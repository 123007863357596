import { isPlatform } from '@ionic/react';
import { StorageKeys } from '../types/storage';
import { Storage } from '@ionic/storage';

export const IOStore = new Storage();
IOStore.create();

export const storageGet = async (key: StorageKeys) => {
  if (isPlatform('mobileweb')) {
    return localStorage.getItem(key);
  } else {
    return IOStore.get(key);
  }
};

export const storageSet = async (key: StorageKeys, value: string) => {
  if (isPlatform('mobileweb')) {
    localStorage.setItem(key, value);
  } else {
    IOStore.set(key, value);
  }
};

export const storageRemove = async (key: StorageKeys) => {
  if (isPlatform('mobileweb')) {
    localStorage.removeItem(key);
  } else {
    IOStore.remove(key);
  }
};
