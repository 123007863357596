import { createSlice } from '@reduxjs/toolkit';
import { UnAvailabilityState } from './types';

export const initialState: UnAvailabilityState = {
  editingDate: "",
  unavailabilities: [],
};

export const unAvailabilitySlice = createSlice({
  name: 'unAvailability',
  initialState,
  reducers: {
    setEditingDate: (state, action) => {
      state.editingDate = action.payload;
    },
    setUnavailabilities: (state, action) => {
      state.unavailabilities = action.payload;
    }
  },
})

export const { setEditingDate, setUnavailabilities } = unAvailabilitySlice.actions

export default unAvailabilitySlice;
