// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button.custom-icon {
  --background: #3b3b3b;
}
ion-button.custom-icon.wide {
  min-width: 250px;
}
ion-button.custom-icon:not(.text-under) .text-icon-container {
  display: flex;
  align-items: center;
}
ion-button.custom-icon:not(.text-under) .text-icon-container ion-icon {
  padding: 5px;
}
ion-button.custom-icon .image {
  width: 20px;
}
ion-button.custom-icon .label {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 16px;
  margin: 0 10px 5px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-icon-button/custom-icon-button.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AACI;EACE,aAAA;EACA,mBAAA;AACN;AAAM;EACE,YAAA;AAER;AAEE;EACE,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AAAJ","sourcesContent":["ion-button.custom-icon {\n  --background: #3b3b3b;\n  &.wide {\n    min-width: 250px;\n  }\n  &:not(.text-under) {\n    .text-icon-container {\n      display: flex;\n      align-items: center;\n      ion-icon {\n        padding: 5px;\n      }\n    }\n  }\n  .image {\n    width: 20px;\n  }\n  .label {\n    display: flex;\n    flex-flow: column nowrap;\n    align-items: center;\n    font-size: 16px;\n    margin: 0 10px 5px 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
