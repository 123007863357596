import { Geolocation } from '@capacitor/geolocation';
import { isPlatform } from '@ionic/react';
import { apiCall } from '../../helpers/apiCalls';
import { HTTPMethods } from '../../types/api';
import { ApiRoutes } from '../../types/routes';
import { LatLong } from './types';
import { fetchUserData } from '../../utils/users';
import { UnknownAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

type CustomGeolocationPosition = {
  coords: {
    latitude: number;
    longitude: number;
    altitude?: number | null;
    accuracy: number;
    altitudeAccuracy?: number | null;
    heading?: number | null;
    speed?: number | null;
  };
  timestamp: number;
};

export const getCurrentPosition = async (): Promise<CustomGeolocationPosition | false> => {
  if (isPlatform('hybrid')) {
    // Use Capacitor Geolocation for mobile
    const hasPermission = await Geolocation.checkPermissions();
    if (hasPermission.coarseLocation === "granted" ||
        hasPermission.location === "granted"
    ) {
      const position = await Geolocation.getCurrentPosition();
      return position;
    } else {
      return false;
    }
  } else {
    // Use Browser Geolocation API for web
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
};

export const deletePicture = async (id: number) =>  {
  try {
    const body = {
      id
    }
    const response = await apiCall({
      url: ApiRoutes.DELETE_PICTURE, method: HTTPMethods.POST, data: body,
      auth: true,
    });
    return response.success;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    return false;
  }
};

export const handleSubmitPicture = async (file: File | null, dispatch: Dispatch<UnknownAction>) => {
  if (file) {
    const formData = new FormData();
    formData.append('profilePicture', file);
    try {
      const response = await apiCall({
        url: ApiRoutes.UPLOAD_PICTURE, method: HTTPMethods.POST, formData: formData,
        auth: true,
      });
      if (response.success) {
        await fetchUserData(dispatch);
        console.log('Profile picture uploaded successfully');
      } else {
        console.error('Failed to upload profile picture');
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  }
};

export const setStatus = async (available: boolean, selectedDistance: number, latLong: LatLong | undefined, price: number) => {
  try {
    const body = {
      availability: available,
      distance: selectedDistance,
      latitude: `${latLong?.lat ? latLong?.lat : ''}`,
      longitude: `${latLong?.long ? latLong?.long : ''}`,
      price: price
    };
    await apiCall({
      url: ApiRoutes.SET_AVAILABILITY, method: HTTPMethods.POST, data: body, auth: true
    });
  } catch (e) {
    console.log("error", e);
  }
}