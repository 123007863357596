// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-page .users {
  margin: 10px 5px;
  padding: 10px 5px;
  border: 1px solid black;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/users/admin-users.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".users-page {\n  .users {\n    margin: 10px 5px;\n    padding: 10px 5px;\n    border: 1px solid black;\n    border-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
